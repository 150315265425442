import { createTheme } from '@mui/material';

/**
 * テーマカラーの設定
 */
export default createTheme({
  palette: {
    background: {
      default: '#EFF5F8'
    },
    primary: {
      main: '#362f5f',
      // main: '#CBCFC7',
    },
    secondary: {
      main: '#FFFFFF',
    },
    info: {
      main: '#ced7dc',
    },
    error:{
      main: '#ff0000',
    }
  },
  typography: {
    allVariants: {
      color: '#362f5f',
    },
    fontFamily: [
      'Inter',
    ].join(','),
    caption: {
      fontSize: "0.9rem"
    },
    body1: {
      fontSize: "1.1rem"
    },
    body2: {
      fontSize: "0.95rem"
    }
  }
});
