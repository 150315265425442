import { Toolbar, Typography, AppBar, Stack } from "@mui/material";

/**
 * 患者側ページ全般のアプリバー
 * @constructor
 * @group Components
 * @category components
 */
const PatientAppBar = () =>
  <AppBar color='default' position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={1}>
    <Toolbar>
      <Stack sx={{ width: "100%" }} direction="column" spacing={0} alignItems='center' justifyContent='center'>
        <Typography variant='h5' fontWeight='bold'>coMect</Typography>
        <Typography variant='caption'>～ クリニック・病院の診察予約サービス ～</Typography>
      </Stack>
    </Toolbar>
  </AppBar>;

export default PatientAppBar;