import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FC } from "react";
import { Gender } from "../../types/Gender";

export type GenderSelectFormProps = {
  /** 現在値 */
  value?: Gender,
  /** 値が変化した時のコールバック */
  onChange?: (gender: number) => void;
};

/**
 * 性別選択フォームコンポーネント
 * @param value 現在値
 * @param onChange 値が変更された時のコールバック
 * @constructor
 * @group Components
 * @category components
 */
const GenderSelectForm: FC<GenderSelectFormProps> = ({
  value = undefined,
  onChange = undefined
}) =>
  <RadioGroup
    row
    value={value}
    onChange={(e) => onChange ? onChange(Number(e.target.value)) : undefined}
  >
    <FormControlLabel value={1} control={<Radio />} label="男性" />
    <FormControlLabel value={2} control={<Radio />} label="女性" />
    <FormControlLabel value={9} control={<Radio />} label="その他" />
  </RadioGroup>


export default GenderSelectForm;