import { Box, Stack, Typography } from "@mui/material";
import PatientPageBase from "../PageBase/PatientPageBase";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const NotFoundClinicPage = () => <PatientPageBase>
  <Stack direction='column' m={2}>
    <Box mt={2} mb={4}>
      <Typography variant='subtitle1' fontWeight='bold'>
        クリニックが見つかりませんでした。
      </Typography>
      <Typography variant='body2'>
        クリニックのホームページもしくはLINE公式アカウントから再度アクセスしてください。
      </Typography>
    </Box>
  </Stack>
</PatientPageBase>;

export default NotFoundClinicPage;