import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ErrorBoundary } from "react-error-boundary";
import { ja } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { RecoilRoot } from "recoil";
import { ConfirmProvider } from "material-ui-confirm";
import themes from './themes';
import reportWebVitals from './reportWebVitals';
import App from './App';
import isDebug from "./utils/constants";
import ResultAlert from './components/ResultAlert/ResultAlert';
import ErrorPage from "./features/ErrorPage/ErrorPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={themes}>
      <RecoilRoot>
        <ErrorBoundary
          fallback={
            <ErrorPage />
          }
        >
          <ConfirmProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja}
            >
              <Suspense fallback={<CircularProgress />}>
                <App />
              </Suspense>
            </LocalizationProvider>
          </ConfirmProvider>
        </ErrorBoundary>
        <ResultAlert />
        {isDebug && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </RecoilRoot>
    </ThemeProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
