import { AlertColor } from "@mui/material";
import { atom } from "recoil";
import { ReactNode } from "react";

export type ResultAlertState = {
  /** アラート表示かどうか */
  isOpen: boolean;
  /** アラートのタイプ */
  result: AlertColor;
  /** メッセージ */
  message: ReactNode;
};

const initialState: ResultAlertState = {
  isOpen: false,
  result: 'success',
  message: '',
};

const resultAlertStateAtom = atom({
  key: 'ResultAlertState',
  default: initialState,
});

export default resultAlertStateAtom;