import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type InputFormProps = {
  /** ラベル文字列 */
  label: string;
  /** 必須項目かどうか */
  required?: boolean;
  /** 値が変化した時のコールバック */
  onChange?: (txt: string) => void;
  /** エラー文字列 */
  error?: string | undefined;
  /** 複数行対応かどうか */
  multiline?: boolean;
  /** 最小表示行数 */
  minLines?: number | undefined;
  /** 最大表示行数 */
  maxLines?: number | undefined;
  /** パスワードマスク機能を利用するかどうか */
  password?: boolean;
  /** 入力欄タイプ */
  type?: string;
  /** 現在値 */
  value?: string;
  /** 単位等、入力文字の後ろにつけたいもの */
  endAdornmentStr?: string;
  /** 入力文字の始めにつけたいもの */
  startAdornmentStr?: string;
};

/**
 * テキスト入力フォームコンポーネント
 * @param label 入力欄ラベル
 * @param required 必須項目かどうか
 * @param onChange 値が変更された時のコールバック
 * @param error バリデーションエラー
 * @param multiline 複数行対応かどうか
 * @param minLines 最小表示行数
 * @param maxLines 最大表示行数
 * @param password パスワードマスク機能を利用するかどうか
 * @param type 入力欄タイプ
 * @param value 現在値
 * @param endAdornmentStr 単位等、入力文字の後ろにつけたいもの
 * @param startAdornmentStr 入力文字の始めにつけたいもの
 * @constructor
 * @group Components
 * @category components
 */
const InputForm: FC<InputFormProps> = ({
  label,
  required = false,
  onChange = undefined,
  error = undefined,
  multiline = false,
  minLines = undefined,
  maxLines = undefined,
  password = false,
  type = undefined,
  value = undefined,
  endAdornmentStr = undefined,
  startAdornmentStr = undefined
}) => {
  const [ passwordShowState, setPasswordShowState ] = useState(!password);
  
  const handleClickShowPassword = () => {
    setPasswordShowState((prev) => !prev);
  };
  
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  const currentType = useMemo(() => {
    if (type) {
      return type;
    }
    return passwordShowState ? 'text' : 'password';
  }, [ passwordShowState, type ]);
  
  const endAdornment = useMemo(() => {
    if (password) {
      return <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {passwordShowState ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    }
    if (endAdornmentStr) {
      return <InputAdornment position="end">{endAdornmentStr}</InputAdornment>
    }
    return null
  }, [ endAdornmentStr, password, passwordShowState ]);
  
  const startAdornment = useMemo(() => {
    if (startAdornmentStr) {
      return <InputAdornment position="start">{startAdornmentStr}</InputAdornment>
    }
    return null
  }, [ startAdornmentStr ])
  
  return <FormControl fullWidth error={!!error}>
    <InputLabel htmlFor="filled-adornment-amount">{label}</InputLabel>
    <OutlinedInput
      required={required}
      id="outlined-adornment-amount"
      type={currentType}
      label={label}
      sx={{
        borderRadius: '24px'
      }}
      multiline={multiline}
      minRows={minLines}
      maxRows={maxLines}
      endAdornment={endAdornment}
      startAdornment={startAdornment}
      value={value}
      onChange={(e) =>
        onChange ? onChange(e.target.value ?? '') : undefined
      }
    />
    <FormHelperText component="div" error={!!error}>
      <Typography
        variant="caption"
        style={{ color: error ? 'red' : 'grey' }}
      >
        {error}
      </Typography>
    </FormHelperText>
  </FormControl>
};

export default InputForm;