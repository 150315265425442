import {
  Box,
  Button, Grid, Stack
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useReservationPageState from "./useReservationPageState";
import InputFormLabel from "../../components/InputForm/InputFormLabel";
import InputForm from "../../components/InputForm/InputForm";
import GenderSelectForm from "../../components/GenderSelectForm/GenderSelectForm";
import { Gender } from "../../types/Gender";
import { DateInputForm } from "../../components/DateInputForm/DateInputForm";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const BasicInputStep = () => {
  const {
    state,
    onChangeExamineeFirstName,
    onChangeExamineeLastName,
    onChangeExamineeFirstKana,
    onChangeExamineeLastKana,
    onChangeExamineeEmail,
    onChangeExamineePhone,
    onChangeExamineeGender,
    onChangeExamineeBirthday,
    onClickNextStep,
    onChangeActiveStep,
  } = useReservationPageState();
  
  const overTabletContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} my={2}>
      <Stack spacing={4}>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs={4}>
            <InputFormLabel label="お名前" isRequire />
          </Grid>
          <Grid item xs={4} pr={1}>
            <InputForm label='姓' value={state.examineeFirstName}
                       onChange={onChangeExamineeFirstName} error={state.errors?.examineeFirstName} />
          </Grid>
          <Grid item xs={4} pl={1}>
            <InputForm label='名' value={state.examineeLastName}
                       onChange={onChangeExamineeLastName} error={state.errors?.examineeLastName} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs={4}>
            <InputFormLabel label="よみがな" isRequire />
          </Grid>
          <Grid item xs={4} pr={1}>
            <InputForm label='せい' value={state.examineeFirstKana}
                       onChange={onChangeExamineeFirstKana} error={state.errors?.examineeFirstKana} />
          </Grid>
          <Grid item xs={4} pl={1}>
            <InputForm label='めい' value={state.examineeLastKana}
                       onChange={onChangeExamineeLastKana} error={state.errors?.examineeLastKana} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs={4}>
            <InputFormLabel label="メールアドレス" isRequire />
          </Grid>
          <Grid item xs={8}>
            <InputForm label='メールアドレス' type='email' value={state.examineeEmail}
                       onChange={onChangeExamineeEmail} error={state.errors?.examineeEmail} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs={4}>
            <InputFormLabel label="電話番号" isRequire />
          </Grid>
          <Grid item xs={8}>
            <InputForm label='電話番号' type='tel' value={state.examineePhone}
                       onChange={onChangeExamineePhone} error={state.errors?.examineePhone} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs={4}>
            <InputFormLabel label="性別" isRequire />
          </Grid>
          <Grid item xs={8}>
            <GenderSelectForm
              value={state.examineeGender}
              onChange={(gender) => onChangeExamineeGender(gender as Gender)}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='start'>
          <Grid item xs={4}>
            <InputFormLabel label="生年月日" isRequire />
          </Grid>
          <Grid item xs={4}>
            <DateInputForm
              label="生年月日"
              value={state.examineeBirthday}
              error={state.errors?.examineeBirthday}
              fullWidth
              onChange={(date) => onChangeExamineeBirthday(date || '')}
            />
          </Grid>
        </Grid>
        <Grid container mb={2} alignItems="center" justifyContent="space-between">
          <Grid item>
            <Button variant='contained' color='secondary' sx={{ px: '24px', py: '8px', fontSize: '20px' }}
                    onClick={() => onChangeActiveStep(1)}
                    startIcon={<ArrowBackIcon />}>前のステップへ</Button>
          </Grid>
          <Grid item>
            <Button variant='contained' sx={{ px: '24px', py: '8px', fontWeight: 'bold', fontSize: '20px' }}
                    onClick={() => onClickNextStep()}
                    endIcon={<ArrowForwardIcon />}>次のステップへ</Button>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  </Grid>;
  
  const mobileContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12}>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <InputFormLabel label="お名前" isRequire />
        <Stack direction='row' spacing={1}>
          <InputForm label='姓' value={state.examineeFirstName}
                     onChange={onChangeExamineeFirstName} error={state.errors?.examineeFirstName} />
          <InputForm label='名' value={state.examineeLastName}
                     onChange={onChangeExamineeLastName} error={state.errors?.examineeLastName} />
        </Stack>
        <InputFormLabel label="よみがな" isRequire />
        <Stack direction='row' spacing={1}>
          <InputForm label='せい' value={state.examineeFirstKana}
                     onChange={onChangeExamineeFirstKana} error={state.errors?.examineeFirstKana} />
          <InputForm label='めい' value={state.examineeLastKana}
                     onChange={onChangeExamineeLastKana} error={state.errors?.examineeLastKana} />
        </Stack>
        <InputFormLabel label="メールアドレス" isRequire />
        <InputForm label='メールアドレス' type='email' value={state.examineeEmail}
                   onChange={onChangeExamineeEmail} error={state.errors?.examineeEmail} />
        <InputFormLabel label="電話番号" isRequire />
        <InputForm label='電話番号' type='tel' value={state.examineePhone}
                   onChange={onChangeExamineePhone} error={state.errors?.examineePhone} />
        <InputFormLabel label="性別" isRequire />
        <GenderSelectForm
          value={state.examineeGender}
          onChange={(gender) => onChangeExamineeGender(gender as Gender)}
        />
        <InputFormLabel label="生年月日" isRequire />
        <DateInputForm
          label="生年月日"
          value={state.examineeBirthday}
          error={state.errors?.examineeBirthday}
          fullWidth
          onChange={(date) => onChangeExamineeBirthday(date || '')}
        />
        
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Button variant='contained' color='secondary' sx={{ px: '10px', py: '8px', fontSize: '14px' }}
                    onClick={() => onChangeActiveStep(1)}
                    startIcon={<ArrowBackIcon />}>前のステップへ</Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant='contained' sx={{ px: '10px', py: '8px', fontWeight: 'bold', fontSize: '14px' }}
                    onClick={() => onClickNextStep()}
                    endIcon={<ArrowForwardIcon />}>次のステップへ</Button>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  </Grid>;
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default BasicInputStep;
