import { Box, Button, Grid, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useReservationPageState from "./useReservationPageState";
import InputForm from "../../components/InputForm/InputForm";
import InputFormLabel from "../../components/InputForm/InputFormLabel";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReceptionInputStep = () => {
  const {
    state,
    onChangeHasPatientIdentification,
    onChangePatientIdentification,
    onChangeReservationDetail,
    onClickNextStep,
  } = useReservationPageState();
  
  const mobileContents =
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Stack direction='column' spacing={2}>
          <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
            <InputFormLabel label="当医院の受診" isRequire />
            <ToggleButtonGroup
              color="standard"
              value={state.hasPatientIdentification}
              exclusive
              onChange={(_, value: boolean) => onChangeHasPatientIdentification(value)}
            >
              <ToggleButton disabled={state.hasPatientIdentification} style={{ width: '50%' }} value>
                診察券をお持ちの方
              </ToggleButton>
              <ToggleButton disabled={!state.hasPatientIdentification} style={{ width: '50%' }} value={false}>
                診察券をお持ちでない方
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          {state.hasPatientIdentification ?
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
              <InputFormLabel label="診察券番号" isRequire={false} />
              <InputForm
                label=''
                value={state.patientIdentification}
                onChange={onChangePatientIdentification}
              />
            </Stack>
            : null
          }
          <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
            <InputFormLabel label="症状" />
            <InputForm
              label='症状(自由入力)'
              value={state.reservationDetail}
              multiline
              minLines={4}
              onChange={onChangeReservationDetail}
              error={state.errors?.reservationDetail}
            />
          </Stack>
          <Button variant='contained' sx={{ px: '24px', py: '8px', fontWeight: 'bold' }}
                  onClick={() => onClickNextStep()}
                  endIcon={<ArrowForwardIcon />}>
            {state.prevStep !== 3 ? "次のステップへ" : "確認ステップへ戻る"}
          </Button>
        </Stack>
      </Grid>
    </Grid>;
  
  const overTabletContents =
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={12} my={2}>
        <Stack spacing={4}>
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={4}>
              <InputFormLabel label="当医院の受診" isRequire />
            </Grid>
            <Grid item xs={8}>
              <ToggleButtonGroup
                color="standard"
                value={state.hasPatientIdentification}
                exclusive
                onChange={(_, value: boolean) => onChangeHasPatientIdentification(value)}
              >
                <ToggleButton disabled={state.hasPatientIdentification} style={{ width: '50%' }} value>
                  診察券をお持ちの方
                </ToggleButton>
                <ToggleButton disabled={!state.hasPatientIdentification} style={{ width: '50%' }} value={false}>
                  診察券をお持ちでない方
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {state.hasPatientIdentification ?
            <Grid container direction='row'>
              <Grid item xs={4}>
                <InputFormLabel label="診察券番号" isRequire={false} />
              </Grid>
              <Grid item xs={8}>
                <InputForm label='' value={state.patientIdentification}
                           onChange={onChangePatientIdentification} />
              </Grid>
            </Grid>
            : null}
          <Grid container direction='row'>
            <Grid item xs={4}>
              <InputFormLabel label="症状" />
            </Grid>
            <Grid item xs={8}>
              <InputForm
                label='症状(自由入力)'
                value={state.reservationDetail}
                multiline
                minLines={4}
                onChange={onChangeReservationDetail}
                error={state.errors?.reservationDetail}
              />
            </Grid>
          </Grid>
          <Grid container mb={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Button variant='contained'
                      sx={{ px: '24px', py: '8px', fontWeight: 'bold', fontSize: { xs: '14px', sm: '20px' } }}
                      onClick={() => onClickNextStep()}
                      endIcon={<ArrowForwardIcon />}>{state.prevStep !== 3 ? "次のステップへ" : "確認ステップへ戻る"}</Button>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>;
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default ReceptionInputStep;