import {
  Box, Card, CardContent, CircularProgress,
  Stack, Step, StepLabel, Stepper, Typography
} from "@mui/material";
import { ReactNode, Suspense, useEffect} from "react";
import useReservationPageState from "./useReservationPageState";
import ClinicInfoContent from "./ClinicInfoContent";
import ReceptionInputStep from "./ReceptionInputStep";
import DateTimeInputStep from "./DateTimeInputStep";
import BasicInputStep from "./BasicInputStep";
import ConfirmStep from "./ConfirmStep";
import CompletedStep from "./CompletedStep";
import PatientPageBase from "../PageBase/PatientPageBase";

type StepItemType = {
  index: number;
  label: string;
  children: ReactNode;
};

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReservationPage = () => {
  const { state } = useReservationPageState();
  
  const steps: StepItemType[] = [
    {
      index: 0,
      label: '受付内容',
      children: <ReceptionInputStep />,
    },
    {
      index: 1,
      label: '診察日時',
      children: <DateTimeInputStep />,
    },
    {
      index: 2,
      label: '基本情報',
      children: <BasicInputStep />,
    },
    {
      index: 3,
      label: '確認',
      children: <ConfirmStep />,
    },
    {
      index: 4,
      label: '完了',
      children: <Suspense fallback={<CircularProgress />}>
        <CompletedStep /></Suspense>
    },
  ];
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ state.activeStep ]);
  
  return <PatientPageBase>
    <Stack direction='column'>
      <ClinicInfoContent clinicId={state.clinicId} />
      <Box mt={8} mb={4} sx={{ width: '100%' }}>
        <Stepper activeStep={state.activeStep} alternativeLabel>
          {steps.map((step: StepItemType) => (
            <Step key={step.index}>
              <StepLabel>
                <Typography variant='subtitle2' fontWeight='bold'>
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {state.activeStep === 3 ? // 確認ステップの場合
        <Box mt={2} mb={4}>
          <Typography variant='subtitle1' fontWeight='bold' color='#c62828' mb="2px">
            まだ受付・予約は確定しておりません。
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            内容に間違いがないかご確認ください。
          </Typography>
        </Box> : null}
      {state.activeStep === 4 ? // 完了ステップの場合
        <Box mt={2} mb={4}>
          <Typography variant='subtitle1' fontWeight='bold' color='#4ec4d3' mb="2px">
            受付・予約が完了しました。
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            受付・予約の変更およびキャンセルについては<br />クリニックに直接お問い合わせください。
          </Typography>
        </Box> : null}
      <Card elevation={6}>
        <CardContent sx={{ px: 1 }}>
          <Stack direction='column' justifyContent='center' alignItems='center' mt={2} spacing={4}>
            <Typography variant='h5'>
              {`Step${steps[state.activeStep].index + 1}： ${steps[state.activeStep].label}`}
            </Typography>
            {state.activeStep === 1 || state.activeStep === 2 ? <Box mt={2} mb={4}>
              <Typography variant='subtitle1' fontWeight='bold' mb="2px">
                {state.activeStep === 1 ? "診察予約日時を選択してください。" : " 受診者の情報を入力してください。"}
              </Typography>
            </Box> : null}
            <Suspense fallback={<CircularProgress />}>
              {steps[state.activeStep].children}
            </Suspense>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  </PatientPageBase>
}

export default ReservationPage;