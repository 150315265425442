import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box,
  Grid,
  Stack,
  Table,
  TableBody, TableCell, TableRow,
  Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { FC, useState } from "react";
import IReservationInformation from "../../interfaces/IReservationInformation";
import IClinicInformation from "../../interfaces/IClinicInformation";
import { genderIndex2string, time2string } from "../../utils/converter";

type ReservationDetailContentProps = {
  reservationInfo: IReservationInformation,
  clinicInfo: IClinicInformation,
}

/**
 *
 * @param reservationInfo
 * @param clinicInfo
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReservationDetailContent: FC<ReservationDetailContentProps> = ({ reservationInfo, clinicInfo }) => {
  const [ receptorInfoExpanded, setReceptorInfoExpanded ] = useState<boolean>(true);
  const [ baseInfoExpanded, setBaseInfoExpanded ] = useState<boolean>(true);
  
  const overTabletContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} md={10} lg={7} my={2}>
      <Stack spacing={2}>
        <Stack direction='row' justifyContent='center'>
          <Typography variant='body1' mb={4}>
            予約情報が照会されました。
          </Typography>
          <Typography variant='body1' display='inline' color="#4ec4d3" ml={2}>＜受付済み＞</Typography>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='end'>
          <Typography variant='h5' fontWeight='bold'>
            {clinicInfo.clinicName}
          </Typography>
          <Typography variant='subtitle1'>
            {clinicInfo.departments ? `【${clinicInfo.departments}】` : ''}
          </Typography>
        </Stack>
        <Box />
        <Accordion expanded={receptorInfoExpanded} onChange={() => setReceptorInfoExpanded(!receptorInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受付・予約情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                {/* <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    予約ID
                  </TableCell>
                  <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                    {reservationInfo.id}
                    <CopyToClipboard text={reservationInfo.id} onCopy={() => {
                      openAlert('success', '予約IDをコピーしました。')
                    }}>
                      <IconButton size="small">
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow> */}
                
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    呼び出し番号
                  </TableCell>
                  <TableCell sx={{ width: "60%", color: "#362f5f" }} align="right">
                    {reservationInfo.number.toString().padStart(3, '0')}
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    診察日
                  </TableCell>
                  <TableCell sx={{ width: "60%", color: "#362f5f" }} align="right">
                    {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    診察時間枠
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {`${time2string({
                      hour: reservationInfo.startHour,
                      minute: reservationInfo.startMinute,
                    })}
                        ~
                        ${time2string({
                      hour: reservationInfo.endHour,
                      minute: reservationInfo.endMinute,
                    })}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    当医院の受診
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                  </TableCell>
                </TableRow>
                {reservationInfo.hasPatientIdentification ? <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    診察券番号
                  </TableCell>
                  <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                    {reservationInfo.patientIdentification}
                  </TableCell>
                </TableRow> : null}
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    症状(自由入力)
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right"
                             style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                    {reservationInfo.reservationDetail}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
        
        <Accordion expanded={baseInfoExpanded} onChange={() => setBaseInfoExpanded(!baseInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受診者情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    お名前
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.examineeFirstName}{reservationInfo.examineeLastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    よみがな
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.examineeFirstKana} {reservationInfo.examineeLastKana}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.examineeEmail}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    電話番号
                  </TableCell>
                  <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                    {reservationInfo.examineePhone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    性別
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {genderIndex2string(reservationInfo.examineeGender)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    生年月日
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {format(new Date(reservationInfo.examineeBirthday), `yyyy年M月d日`, { locale: ja })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Grid>
  </Grid>;
  
  const mobileContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} md={10} lg={7} my={2}>
      <Stack spacing={2}>
        <Stack direction='column' justifyContent='center' mb={2}>
          <Typography variant='body1'>
            予約情報が照会されました。
          </Typography>
          <Typography variant='body1' display='inline' color="#4ec4d3">＜受付済み＞</Typography>
        </Stack>
        <Stack direction='column' spacing={0} alignItems='center'>
          <Typography variant='h5' fontWeight='bold'>
            {clinicInfo.clinicName}
          </Typography>
          <Typography variant='subtitle1'>
            {clinicInfo.departments ? `【${clinicInfo.departments}】` : ''}
          </Typography>
        </Stack>
        <Box />
        <Accordion expanded={receptorInfoExpanded} onChange={() => setReceptorInfoExpanded(!receptorInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受付・予約情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
              { /* <Stack direction='column' spacing={0} alignItems='center'>
                <Typography variant='subtitle1' fontWeight='bold'>
                  予約ID
                </Typography>
                <div>
                  {reservationInfo.id}
                  <CopyToClipboard text={reservationInfo.id} onCopy={() => {
                    openAlert('success', '予約IDをコピーしました。')
                  }}>
                    <IconButton size="small">
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </div>
              </Stack> */}
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  呼び出し番号
                </Typography>
                <div>
                  {reservationInfo.number.toString().padStart(3, '0')}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  診察日
                </Typography>
                <div>
                  {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  診察時間枠
                </Typography>
                <div>
                  {`${time2string({
                    hour: reservationInfo.startHour,
                    minute: reservationInfo.startMinute,
                  })}
                        ~
                        ${time2string({
                    hour: reservationInfo.endHour,
                    minute: reservationInfo.endMinute,
                  })}`}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  診察券
                </Typography>
                <div>
                  {reservationInfo.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                </div>
              </Stack>
              
              {reservationInfo.hasPatientIdentification ?
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    診察券番号
                  </Typography>
                  <div>
                    {reservationInfo.patientIdentification}
                  </div>
                </Stack> : null}
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  症状(自由入力)
                </Typography>
                <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                  {
                    reservationInfo.reservationDetail &&
                    reservationInfo.reservationDetail !== "" ?
                      reservationInfo.reservationDetail
                      : "入力無し"
                  }
                </div>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        
        <Accordion expanded={baseInfoExpanded} onChange={() => setBaseInfoExpanded(!baseInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受診者情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
              <Stack direction='column' spacing={0} alignItems='center'>
                <Typography variant='subtitle1' fontWeight='bold'>
                  お名前
                </Typography>
                <div>
                  {reservationInfo.examineeFirstName} {reservationInfo.examineeLastName}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  よみがな
                </Typography>
                <div>
                  {reservationInfo.examineeFirstKana} {reservationInfo.examineeLastKana}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  メールアドレス
                </Typography>
                <div>
                  {reservationInfo.examineeEmail}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  電話番号
                </Typography>
                <div>
                  {reservationInfo.examineePhone}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  性別
                </Typography>
                <div>
                  {genderIndex2string(reservationInfo.examineeGender)}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  生年月日
                </Typography>
                <div>
                  {format(new Date(reservationInfo.examineeBirthday), `yyyy年M月d日`, { locale: ja })}
                </div>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Grid>
  </Grid>
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default ReservationDetailContent;