import { useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useRecoilState, useResetRecoilState } from "recoil";
import { addMonths, getDate, getMonth, getYear, isAfter, isBefore, isSameDay, subMinutes } from "date-fns";
import { useParams } from "react-router-dom";
import { runTransaction, doc, collection } from "firebase/firestore";
import reservationPageStateAtom from "../../recoil/features/ReservationPage/atom";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { getClinicInformation } from "../../repositories/clinicRepository";
import IReservationSetting from "../../interfaces/IReservationSetting";
import { Gender } from "../../types/Gender";
import Time from "../../types/Time";
import { date2DayOfWeek, date2epoch, date2string, dayOfWeekNum2string, time2string } from "../../utils/converter";
import { firestore } from "../../firebase";
import { reserveStatusConverter } from "../../interfaces/IReserveStatus";
import { reservationFrameConverter } from "../../interfaces/IReservationFrame";
import IReservationInformation, {
  reservationInformationConverter,
  validateBasicInputStep
} from "../../interfaces/IReservationInformation";
import { calcReservePoints } from "../../utils/points";

/**
 * @group Components
 * @category features/patient
 */
const useReservationPageState = () => {
  const params = useParams();
  const [ state, setState ] = useRecoilState(reservationPageStateAtom);
  const { openAlert } = useResultAlertState();
  const resetState = useResetRecoilState(reservationPageStateAtom);
  
  const setClinicId = useCallback((clinicId: string) => {
    setState((prev) => ({ ...prev, clinicId }))
  }, [ setState ]);
  
  useEffect(() => {
    if (params.id) {
      setClinicId(params.id);
    }
  }, [ params.id, setClinicId ])
  
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', state.clinicId ],
    () => getClinicInformation(state.clinicId),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
  
  const onChangeActiveStep = useCallback((activeStep: number) => {
    setState((prev) => ({ ...prev, activeStep, prevStep: prev.activeStep }))
  }, [ setState ]);
  const setBaseSettingItem = useCallback((baseSettingItem: IReservationSetting) => {
    setState((prev) => ({ ...prev, baseSettingItem }))
  }, [ setState ]);
  const onChangeExamineeFirstName = useCallback((examineeFirstName: string) => {
    setState((prev) => ({ ...prev, examineeFirstName }))
  }, [ setState ]);
  const onChangeExamineeLastName = useCallback((examineeLastName: string) => {
    setState((prev) => ({ ...prev, examineeLastName }))
  }, [ setState ]);
  const onChangeExamineeFirstKana = useCallback((examineeFirstKana: string) => {
    setState((prev) => ({ ...prev, examineeFirstKana }))
  }, [ setState ]);
  const onChangeExamineeLastKana = useCallback((examineeLastKana: string) => {
    setState((prev) => ({ ...prev, examineeLastKana }))
  }, [ setState ]);
  const onChangeExamineeEmail = useCallback((examineeEmail: string) => {
    setState((prev) => ({ ...prev, examineeEmail }))
  }, [ setState ]);
  const onChangeExamineePhone = useCallback((examineePhone: string) => {
    setState((prev) => ({ ...prev, examineePhone }))
  }, [ setState ]);
  const onChangeExamineeGender = useCallback((examineeGender: Gender) => {
    setState((prev) => ({ ...prev, examineeGender }))
  }, [ setState ]);
  const onChangeExamineeBirthday = useCallback((examineeBirthday: string) => {
    setState((prev) => ({ ...prev, examineeBirthday }))
  }, [ setState ]);
  const onChangeHasPatientIdentification = useCallback((hasPatientIdentification: boolean) => {
    setState((prev) => ({ ...prev, hasPatientIdentification, patientIdentification: '' }))
  }, [ setState ]);
  const onChangePatientIdentification = useCallback((patientIdentification: string) => {
    setState((prev) => ({ ...prev, patientIdentification }))
  }, [ setState ]);
  const onChangeReservationDetail = useCallback((reservationDetail: string) => {
    setState((prev) => ({ ...prev, reservationDetail }))
  }, [ setState ]);
  const onChangeReservationDate = useCallback((reservationDate: string, startAt: Time, endAt: Time) => {
    setState((prev) => ({ ...prev, reservationDate, startAt, endAt }))
    openAlert('success', '診察日時を選択しました。');
  }, [ openAlert, setState ]);
  const setTapEventId = useCallback((id: string) => {
    setState((prev) => ({ ...prev, tapCalendarEvent: id }))
  }, [ setState ])
  
  const isCurrentBeforeEndAt = (endAtTime: Time, date: Date): boolean => {
    const current = new Date();
    const endAt = new Date(date);
    endAt.setHours(endAtTime.hour, endAtTime.minute, 0, 0)
    if (isSameDay(current, endAt)) {
      return isBefore(current, endAt)
    }
    return true;
  };
  
  const onClickNextStep = useCallback(() => {
    setState((prev) => ({ ...prev, errors: undefined }))
    switch (state.activeStep) {
      case 0:
        setState((prev) => ({ ...prev, activeStep: prev.prevStep !== 3 ? 1 : 3, prevStep: 0 }))
        break;
      case 1:
        if (state.reservationDate === '' || !state.startAt || !state.endAt) {
          openAlert('error', '入力情報を確認してください。');
        } else {
          setState((prev) => ({ ...prev, activeStep: prev.prevStep !== 3 ? 2 : 3, prevStep: 1 }));
        }
        break;
      case 2:
        const basicInputErrors = validateBasicInputStep(state.examineeFirstName, state.examineeLastName,
          state.examineeFirstKana, state.examineeLastKana, state.examineeBirthday, state.examineeEmail, state.examineePhone);
        if (basicInputErrors) {
          console.log(basicInputErrors.examineeBirthday)
          setState((prev) => ({ ...prev, errors: basicInputErrors }))
          openAlert('error', '入力情報を確認してください。');
        } else {
          setState((prev) => ({ ...prev, activeStep: 3, prevStep: 2 }))
        }
        break;
      default:
    }
  }, [ openAlert, setState, state ])
  
  const reserve = useCallback(async () => {
    if (!clinicInfo) {
      throw new Error("診療所情報の取得に失敗しました。")
    }
    
    const now = new Date()
    const reservationPeriodDate = addMonths(new Date(getYear(now), getMonth(now), getDate(now), state.startAt.hour, state.startAt.minute), Number.parseInt(clinicInfo.reservationPeriod, 10))
    if (isAfter(new Date(state.reservationDate), reservationPeriodDate)) {
      throw new Error("選択した時間の予約は、現在受付停止中です。")
    }
    
    const docId = `${date2string(new Date(state.reservationDate))} ${time2string(state.startAt)}`
    const statusDocRef = doc(firestore, "clinics", state.clinicId, "status", docId).withConverter(reserveStatusConverter);
    const stopAcceptingDocRef = doc(firestore, "reservation_settings", state.clinicId, "stop_accepting", docId);
    // 指定日予約枠
    const daySettingDocRef = doc(firestore, "reservation_settings", state.clinicId, "day_settings", date2string(new Date(state.reservationDate)), "reservation_frames", time2string(state.startAt)).withConverter(reservationFrameConverter);
    // 曜日設定予約枠
    const dowStr = dayOfWeekNum2string(date2DayOfWeek(new Date(state.reservationDate)));
    const baseSettingDocRef = doc(firestore, "reservation_settings", state.clinicId, "base_settings", dowStr as string, "reservation_frames", time2string(state.startAt)).withConverter(reservationFrameConverter)
    
    let reserveId = "";
    await runTransaction(firestore, async (transaction) => {
      // 一時受付停止状態の確認
      const stopAcceptingSnapshot = await transaction.get(stopAcceptingDocRef);
      if (stopAcceptingSnapshot.exists()) {
        throw new Error("選択した時間の予約は、現在受付停止中です。")
      }
      
      const daySettingsSnapshot = await transaction.get(daySettingDocRef);
      const reserveDate = new Date(state.reservationDate)
      // 当日予約可能かを確認する
      if (isSameDay(now, reserveDate)) {
        if (!clinicInfo.isAvailableSameDayReserve) {
          throw new Error("当日の予約は受け付けておりません。お電話にてお問い合わせをお願い致します。")
        }
      }
      
      let targetFrame;
      if (daySettingsSnapshot.exists()) {
        // 指定日予約枠設定がある場合
        targetFrame = daySettingsSnapshot.data()
        const endDateTime = new Date(reserveDate.getFullYear(), reserveDate.getMonth(), reserveDate.getDate(), targetFrame.endAt.hour, targetFrame.endAt.minute);
        if (isAfter(now, subMinutes(endDateTime, parseInt(clinicInfo.sameDayReservePeriod, 10)))) {
          throw new Error("選択した時間の予約は、現在受付停止中です。")
        }
      } else {
        // 曜日設定を確認
        const baseSettingsSnapshot = await transaction.get(baseSettingDocRef);
        if (!baseSettingsSnapshot.exists()) {
          throw new Error("選択した予約枠は削除されたか、予約出来ない設定になっています。")
        }
        targetFrame = baseSettingsSnapshot.data();
        const endDateTime = new Date(reserveDate.getFullYear(), reserveDate.getMonth(), reserveDate.getDate(), targetFrame.endAt.hour, targetFrame.endAt.minute);
        if (isAfter(now, subMinutes(endDateTime, parseInt(clinicInfo.sameDayReservePeriod, 10)))) {
          throw new Error("選択した時間の予約は、現在受付停止中です。")
        }
      }
      
      // 消費ポイント数
      const reservePoint = calcReservePoints(targetFrame.numberOfPeople, targetFrame.points);
      
      const statusSnapshot = await transaction.get(statusDocRef);
      let nextNum = 1;
      let nextRemainDataNum = 1;
      if (statusSnapshot.exists()) {
        const statusData = statusSnapshot.data();
        if (statusData.currentPoints + reservePoint > statusData.totalPoints) {
          throw new Error("この時間の予約受付は終了致しました。\n別の時間を選択してください。")
        }
        nextNum = statusData.maxNum + 1;
        nextRemainDataNum = statusData.remainDataNum + 1;
        transaction.update(statusDocRef, {
          maxNum: nextNum,
          remainDataNum: nextRemainDataNum,
          currentPoints: statusData.currentPoints + reservePoint,
        })
      } else {
        // 予約枠に対して初回予約時（Statusが無い場合）
        // Statusを作成
        const reservationDate = new Date(state.reservationDate);
        if (daySettingsSnapshot.exists()) {
          // 指定日予約枠設定がある場合
          const frame = daySettingsSnapshot.data()
          const { points } = frame;
          transaction.set(statusDocRef, {
            currentNum: 0,
            maxNum: nextNum,
            totalPoints: points,
            currentPoints: reservePoint,
            calledPoints: 0,
            startAt: new Date(docId).getTime(),
            endAt: new Date(`${date2string(new Date(state.reservationDate))} ${time2string(state.endAt)}`).getTime(),
            absenteeNum: 0,
            date: new Date(reservationDate.getFullYear(), reservationDate.getMonth(), reservationDate.getDate()).getTime(),
            pointPerPeople: reservePoint,
            remainDataNum: nextRemainDataNum,
          })
        } else {
          // 曜日設定を確認
          const baseSettingsSnapshot = await transaction.get(baseSettingDocRef);
          if (!baseSettingsSnapshot.exists()) {
            throw new Error("選択した予約枠は削除されたか、予約出来ない設定になっています。")
          }
          const frame = baseSettingsSnapshot.data();
          const { points } = frame;
          transaction.set(statusDocRef, {
            currentNum: 0,
            maxNum: nextNum,
            totalPoints: points,
            currentPoints: reservePoint,
            calledPoints: 0,
            startAt: new Date(docId).getTime(),
            endAt: new Date(`${date2string(new Date(state.reservationDate))} ${time2string(state.endAt)}`).getTime(),
            absenteeNum: 0,
            date: new Date(reservationDate.getFullYear(), reservationDate.getMonth(), reservationDate.getDate()).getTime(),
            pointPerPeople: reservePoint,
            remainDataNum: nextRemainDataNum,
          })
        }
      }
      
      // 予約情報の作成（reservesコレクション）
      const reservationInfo: IReservationInformation = {
        id: '',
        clinicId: state.clinicId,
        number: nextNum,
        examineeFirstName: state.examineeFirstName,
        examineeLastName: state.examineeLastName,
        examineeFirstKana: state.examineeFirstKana,
        examineeLastKana: state.examineeLastKana,
        examineeEmail: state.examineeEmail,
        examineePhone: state.examineePhone,
        examineeGender: state.examineeGender,
        examineeBirthday: date2epoch(state.examineeBirthday),
        hasPatientIdentification: state.hasPatientIdentification,
        patientIdentification: state.patientIdentification,
        reservationDetail: state.reservationDetail,
        reservationDate: date2epoch(state.reservationDate),
        startHour: state.startAt.hour,
        startMinute: state.startAt.minute,
        endHour: state.endAt.hour,
        endMinute: state.endAt.minute,
        point: reservePoint,
        reserveStatus: 1, // 受付前
        createdAt: new Date(),
        isEnableLineNotify: false,
        lineUserId: null,
        dayOfWeek: date2DayOfWeek(new Date(state.reservationDate))
      };
      const reserveDocRef = doc(collection(firestore, 'reserves')).withConverter(reservationInformationConverter);
      transaction.set(reserveDocRef, reservationInfo)
      reserveId = reserveDocRef.id;
    })
    setState((prev) => ({ ...prev, activeStep: 4, reservationId: reserveId }));
  }, [ clinicInfo, state, setState ]);
  
  const reserveMutate = useMutation<void, Error>(
    () => reserve(),
    {
      onSuccess: () => {
        openAlert('success', '予約を受け付けました。');
      },
      onError: (error) => {
        openAlert('error', error.message)
      }
    }
  )
  
  const onClickReserve = useCallback(() => {
    void reserveMutate.mutate();
  }, [ reserveMutate ]);
  
  return {
    state,
    resetState,
    isLoading: reserveMutate.isLoading,
    clinicInfo,
    setBaseSettingItem,
    onChangeActiveStep,
    onChangeExamineeFirstName,
    onChangeExamineeLastName,
    onChangeExamineeFirstKana,
    onChangeExamineeLastKana,
    onChangeExamineeEmail,
    onChangeExamineePhone,
    onChangeExamineeGender,
    onChangeExamineeBirthday,
    onChangeHasPatientIdentification,
    onChangePatientIdentification,
    onChangeReservationDetail,
    onChangeReservationDate,
    onClickNextStep,
    onClickReserve,
    setTapEventId,
    isCurrentBeforeEndAt,
  };
}

export default useReservationPageState;
