import { atom } from "recoil";
import IReservationSetting from "../../../interfaces/IReservationSetting";
import { ReservationInformationErrors } from "../../../interfaces/IReservationInformation";
import Time from "../../../types/Time";
import { Gender } from "../../../types/Gender";

export type ReservationPageState = {
  /** 以前のステップ */
  prevStep: number,
  /** 現在のステップ */
  activeStep: number,
  /** 指定曜日の予約枠設定 */
  baseSettingItem: IReservationSetting | undefined,
  /** クリニックのユーザーID */
  clinicId: string,
  /** (受診者の)姓 */
  examineeFirstName: string,
  /** 名 */
  examineeLastName: string,
  /** せい */
  examineeFirstKana: string,
  /** めい */
  examineeLastKana: string,
  /** メールアドレス */
  examineeEmail: string,
  /** 電話番号 */
  examineePhone: string,
  /** 性別 */
  examineeGender: Gender,
  /** 生年月日 */
  examineeBirthday: string,
  /** 診察券をお持ちかどうか */
  hasPatientIdentification: boolean,
  /** 診察券番号 */
  patientIdentification: string,
  /** 受診内容 */
  reservationDetail: string,
  /** 受診日 */
  reservationDate: string,
  /** 診察予約枠の開始時刻 */
  startAt: Time,
  /** 診察予約枠の終了時刻 */
  endAt: Time,
  /** 通知方法 */
  notificationType: string,
  /** バリデーションエラー */
  errors: ReservationInformationErrors | undefined;
  /** 予約ID */
  reservationId: string,
  /** 選択中カレンダーイベント */
  tapCalendarEvent: string,
};

const initialState: ReservationPageState = {
  prevStep: -1,
  activeStep: 0, // Stepper状態
  baseSettingItem: undefined,
  // クリニックID
  clinicId: '',
  // 受診者
  examineeFirstName: '',
  examineeLastName: '',
  examineeFirstKana: '',
  examineeLastKana: '',
  examineeEmail: '',
  examineePhone: '',
  examineeGender: 1,
  examineeBirthday: '2000-01-01',
  hasPatientIdentification: true,
  patientIdentification: '',
  reservationDetail: '',
  reservationDate: '',
  startAt: {
    hour: 0,
    minute: 0,
  },
  endAt: {
    hour: 0,
    minute: 0
  },
  // 通知方法
  notificationType: 'email',
  // バリデーションエラー
  errors: undefined,
  // 予約ID
  reservationId: '',
  tapCalendarEvent: '',
};

const reservationPageStateAtom = atom({
  key: 'ReservationPageState',
  default: initialState,
});

export default reservationPageStateAtom;