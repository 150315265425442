import { FC } from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import IReservationInformation from "../../interfaces/IReservationInformation";
import ReservationListItemContent from "./ReservationListItemContent";

export type ReservationSearchResultPageProps = {
  reserves: IReservationInformation[] | undefined;
}

/**
 *
 * @param reserves
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReservationSearchResultPage: FC<ReservationSearchResultPageProps> = ({ reserves }) => {
  const overTabletContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} my={2}>
      {reserves && reserves.length > 0 ?
        reserves.map((item) =>
          <ReservationListItemContent
            key={item.id}
            reservationInfo={item}
          />
        )
        :
        <div>診察予約がありません。</div>
      }
    </Grid>
  </Grid>;
  
  const mobileContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} my={2}>
      {reserves && reserves.length > 0 ?
        reserves.map((item) =>
          <ReservationListItemContent
            key={item.id}
            reservationInfo={item}
          />
        )
        :
        <div>診察予約がありません。</div>
      }
    </Grid>
  </Grid>
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default ReservationSearchResultPage;