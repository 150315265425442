/**
 * １予約に必要となるポイントを計算し返す。
 * @param numberOfPeople 予約可能人数
 * @param numberOfPoints 予約枠ポイント
 * @return {number} １予約に必要となるポイントが返る。
 * @category utils
 */
export const calcReservePoints = (numberOfPeople: number, numberOfPoints: number): number =>
  (numberOfPoints - numberOfPoints % numberOfPeople) / numberOfPeople;

export const dummy = () => {
  console.log("Test method")
}