import React, { Suspense } from 'react';
import './App.css';
import { CircularProgress, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./features/ErrorPage/ErrorPage";
import NotFoundClinicPage from "./features/NotFoundClinicPage/NotFoundClinicPage";
import ReservationSearchPage from "./features/ReservationSearchPage/ReservationSearchPage";
import ReservationPage from "./features/ReservationPage/ReservationPage";
import ReservationDetailPage from "./features/ReservationDetailPage/ReservationDetailPage";
import {
  errorPagePath,
  notFoundClinicPath,
  reservationPagePath,
  reservationSearchPagePath,
  reservationDetailPagePath
} from "./urls";

const App = () => (
  <div className="App">
    <CssBaseline>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NotFoundClinicPage />} />
          <Route path={notFoundClinicPath} element={<NotFoundClinicPage />} />
          <Route path={errorPagePath} element={<ErrorPage />} />
          <Route path={reservationSearchPagePath} element={<ReservationSearchPage />} />
          <Route path={reservationPagePath} element={<ErrorBoundary
            fallback={
              <ErrorPage />
            }
          >
            <ReservationPage />
          </ErrorBoundary>} />
          <Route path={reservationDetailPagePath}
                 element={
                   <ErrorBoundary
                     fallback={
                       <ErrorPage />
                     }
                   >
                     <Suspense fallback={<CircularProgress />}>
                       <ReservationDetailPage />
                     </Suspense>
                   </ErrorBoundary>
                 }
          />
        </Routes>
      </BrowserRouter>
    </CssBaseline>
  </div>
);

export default App;
