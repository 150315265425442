import { addDays, addMonths, format, getDate, getMonth, getYear, isAfter, isSameDay, subMinutes } from "date-fns";
import { useCallback, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack, Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import FullCalendar, { DatesSetArg } from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import { useMutation, useQuery } from "react-query";
import { ja } from "date-fns/locale";
import useReservationPageState from "./useReservationPageState";
import { date2DayOfWeek, date2string, dayOfWeekNum2string, string2time, time2string } from "../../utils/converter";
import { getPeriodDateList, getPrevDate } from "../../utils/datetime";
import Time from "../../types/Time";
import {
  getReservationBaseFrames,
  getReservationBaseSettings,
  getReservationDayFrames,
  getReservationDaySettingsFromDate, isReserveAvailable, isStopAccepting
} from "../../repositories/reservationSettingRepository";
import IReservationFrame from "../../interfaces/IReservationFrame";
import IReservationSetting from "../../interfaces/IReservationSetting";
import "./fullcalendar-style.css";
import { calcReservePoints } from "../../utils/points";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const DateTimeInputStep = () => {
  const {
    state,
    clinicInfo,
    onChangeReservationDate,
    onClickNextStep,
    onChangeActiveStep,
    setTapEventId,
    isCurrentBeforeEndAt
  } = useReservationPageState();
  
  const [ isEventLoading, setIsEventLoading ] = useState(false);
  const [ events, setEvents ] = useState<EventInput[]>([]);
  const [ currentDateArg, setCurrentDateArg ] = useState<DatesSetArg | undefined>(undefined);
  const [ hiddenDayOfWeek, setHiddenDayOfWeek ] = useState<number[]>([]);
  
  const [ slotMinTime, setSlotMinTime ] = useState('');
  const [ slotMaxTime, setSlotMaxTime ] = useState('');
  
  /**
   * 予約枠基本設定の取得クエリ
   */
  const { data: reservationBaseSettings } = useQuery(
    [ 'getReservationBaseSettings', state.clinicId ],
    () => getReservationBaseSettings(state.clinicId),
  );
  
  /**
   * 診療時間（Min）情報
   */
  const getSlotMinTime = useCallback((daySettings: IReservationSetting[]) => {
    // console.debug(`---START--- slotMinTime ${new Date().getTime().toString()}`);
    if (daySettings == null) {
      return '';
    }
    if (reservationBaseSettings?.length !== 7) {
      return '';
    }
    const daySettingsStartAt: Time[] = [];
    daySettings.forEach(daySetting => {
      daySettingsStartAt.push(daySetting.openAt);
    });
    const startAtList = [ ...daySettingsStartAt, reservationBaseSettings[0].openAt, reservationBaseSettings[1].openAt,
      reservationBaseSettings[2].openAt, reservationBaseSettings[3].openAt, reservationBaseSettings[4].openAt,
      reservationBaseSettings[5].openAt, reservationBaseSettings[6].openAt ].filter(e => e !== undefined);
    startAtList.sort((first, second) => {
      if (!first || !second) {
        return 0;
      }
      if (first.hour < second.hour) {
        return -1;
      }
      if (first.hour === second.hour) {
        if (first.minute < second.minute) {
          return -1;
        }
      }
      return 1;
    });
    const minTime = startAtList[0];
    let result
    if (minTime.minute < 30) {
      result = `${minTime.hour}:00`
    } else {
      result = `${minTime.hour}:30`
    }
    // console.log(`---END--- slotMinTime ${new Date().getTime().toString()}`)
    return result;
  }, [ reservationBaseSettings ]);
  
  /**
   * 診療時間（Max）情報
   */
  const getSlotMaxTime = useCallback((daySettings: IReservationSetting[]) => {
    // console.log(`---START--- slotMaxTime ${new Date().getTime().toString()}`);
    if (daySettings == null) {
      return '';
    }
    if (reservationBaseSettings?.length !== 7) {
      return '';
    }
    const daySettingsEndAt: Time[] = [];
    daySettings.forEach(daySetting => {
      daySettingsEndAt.push(daySetting.openAt);
    });
    const endAtList = [ ...daySettingsEndAt, reservationBaseSettings[0].closeAt, reservationBaseSettings[1].closeAt,
      reservationBaseSettings[2].closeAt, reservationBaseSettings[3].closeAt, reservationBaseSettings[4].closeAt,
      reservationBaseSettings[5].closeAt, reservationBaseSettings[6].closeAt ].filter(e => e !== undefined);
    endAtList.sort((first, second) => {
      if (!first || !second) {
        return 0;
      }
      if (first.hour > second.hour) {
        return -1;
      }
      if (first.hour === second.hour) {
        if (first.minute > second.minute) {
          return -1;
        }
      }
      return 1;
    });
    // console.log(`---END--- slotMaxTime ${new Date().getTime().toString()}`);
    return time2string(endAtList[0]);
  }, [ reservationBaseSettings ]);
  
  const getBaseEvent = useCallback((frameItem: IReservationFrame, date: Date) => ({
    id: `${date2string(date)} ${time2string(frameItem.startAt)}`,
    title: "",
    startStr: time2string(frameItem.startAt),
    endStr: time2string(frameItem.endAt),
    start: `${date2string(date)} ${time2string(frameItem.startAt)}`,
    end: `${date2string(date)} ${time2string(frameItem.endAt)}`,
    backgroundColor: '#9FDAF8',
    borderColor: '#9FDAF8',
    color: '#9FDAF8',
  }), []);
  
  const getNoneEvent = useCallback((date: Date) => ({
    start: `${date2string(date)}`,
    display: "background",
    color: "#d3d3d3",
  }), []);
  
  const getReservationEvents = async (start: Date, end: Date, hidden: number[]) => {
    // console.log(`---START--- getReservationEvents ${new Date().getTime().toString()}`);
    
    const dayReservationEvents: EventSourceInput | undefined = [];
    const baseReservationEvents: EventSourceInput | undefined = [];
    const noneEvents: EventSourceInput | undefined = [];
    
    if (!clinicInfo) {
      const dateList = getPeriodDateList(start, end);
      for (let i = 0; i < dateList.length; i += 1) {
        const date = dateList[i];
        noneEvents.push(getNoneEvent(date))
      }
      return noneEvents;
    }
    
    const now = new Date()
    const reservationPeriodDate = addMonths(new Date(getYear(now), getMonth(now), getDate(now)), Number.parseInt(clinicInfo.reservationPeriod, 10))
    if (isAfter(start, reservationPeriodDate)) {
      const dateList = getPeriodDateList(start, end);
      for (let i = 0; i < dateList.length; i += 1) {
        const date = dateList[i];
        noneEvents.push(getNoneEvent(date))
      }
      return noneEvents;
    }
    let endDate = new Date(end)
    if (isAfter(end, reservationPeriodDate)) {
      endDate = reservationPeriodDate;
    }
    
    const todayZeroTime = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // startAtは時間を考慮していないので、本日の過ぎた時間分の予約枠も取得される
    const daySettings = await getReservationDaySettingsFromDate(state.clinicId, todayZeroTime.getTime(), endDate.getTime());
    setSlotMinTime(getSlotMinTime(daySettings));
    setSlotMaxTime(getSlotMaxTime(daySettings));
    
    // 当日予約を許可するかどうか
    const isEnableTodayReservation = clinicInfo.isAvailableSameDayReserve;
    
    for (let i = 0; i < daySettings.length; i += 1) {
      const reservationDaySetting = daySettings[i];
      const date = new Date(reservationDaySetting.dayOfWeekOrDate);
      
      // 非表示の曜日の場合はスキップする。
      const dowNum = date.getDay();
      if (hidden.indexOf(dowNum) > -1) {
        continue;
      }
      if (isSameDay(now, date)) {
        if (!isEnableTodayReservation) {
          // 当日予約不可の場合取得をスキップ
          continue;
        }
      }
      const reservationFrames = await getReservationDayFrames(state.clinicId, date);
      reservationFrames.map(async (frameItem) => {
        const endDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), frameItem.endAt.hour, frameItem.endAt.minute);
        if (isAfter(now, subMinutes(endDateTime, parseInt(clinicInfo.sameDayReservePeriod, 10)))) {
          // 現在時刻が予約枠開始時間を過ぎていたらスキップする
          return;
        }
        const perPoints = calcReservePoints(frameItem.numberOfPeople, frameItem.points);
        const isReserveAble = await isReserveAvailable(state.clinicId, date, frameItem.startAt, perPoints);
        const isStopAccept = await isStopAccepting(state.clinicId ?? '', date, frameItem.startAt);
        if (isReserveAble && !isStopAccept) {
          dayReservationEvents.push(getBaseEvent(frameItem, date));
        }
      });
    }
    
    // 昨日までをイベント無しに設定する
    if (isAfter(new Date(), start)) {
      const pastDayList = getPeriodDateList(start, getPrevDate(new Date()));
      pastDayList.forEach((date) => {
        noneEvents.push(getNoneEvent(date));
      });
    }
    
    // 当日分
    if (!isEnableTodayReservation) {
      noneEvents.push(getNoneEvent(now))
    } else {
      const todayDoW = date2DayOfWeek(now)
      const todayDoWStr = dayOfWeekNum2string(todayDoW)
      // 指定日設定での当日分の予約枠が無ければ基本設定を適用
      if (!(daySettings && daySettings.some(el => isSameDay(new Date(el.dayOfWeekOrDate), now)))) {
        // 非表示の曜日チェック
        if (hidden.findIndex((value) => value === todayDoW) === -1) {
          if (!reservationBaseSettings) {
            noneEvents.push(getNoneEvent(now))
          } else if (reservationBaseSettings[todayDoW].isOpen) {
            const frames = await getReservationBaseFrames(state.clinicId, todayDoWStr);
            // eslint-disable-next-line no-restricted-syntax
            for (const item of frames) {
              if (isCurrentBeforeEndAt(item.endAt, now) && reservationBaseSettings[todayDoW].isReserveFramesShow) {
                const isStopAccept = await isStopAccepting(state.clinicId ?? '', now, item.startAt);
                if (isStopAccept) {
                  continue;
                }
                const perPoints = calcReservePoints(item.numberOfPeople, item.points);
                const isReserveAble = await isReserveAvailable(state.clinicId, now, item.startAt, perPoints);
                if (isReserveAble) {
                  baseReservationEvents.push(getBaseEvent(item, now))
                }
              }
            }
          } else {
            noneEvents.push(getNoneEvent(now));
          }
        }
      }
    }
    
    const dateList = getPeriodDateList(addDays(new Date(), 1), end);
    
    // 次の日以降分
    for (let i = 0; i < dateList.length; i += 1) {
      const date = dateList[i];
      if (isAfter(date, endDate)) {
        noneEvents.push(getNoneEvent(date))
        continue;
      }
      // 指定日設定での予約枠があれば、基本設定の予約枠は無視する。
      if (daySettings && daySettings.some(el => isSameDay(new Date(el.dayOfWeekOrDate), date))) {
        continue;
      }
      const dow = date2DayOfWeek(date)
      const dowStr = dayOfWeekNum2string(dow)
      if (!reservationBaseSettings) {
        noneEvents.push(getNoneEvent(date));
        continue;
      }
      // 非表示の曜日チェック
      if (hidden.indexOf(date.getDay()) > -1) {
        noneEvents.push(getNoneEvent(date));
        continue;
      }
      
      if (reservationBaseSettings[dow].isOpen) {
        const reservationFrames = await getReservationBaseFrames(state.clinicId, dowStr);
        // eslint-disable-next-line no-restricted-syntax
        for (const frameItem of reservationFrames) {
          if (isCurrentBeforeEndAt(frameItem.endAt, date) && reservationBaseSettings[dow].isReserveFramesShow) {
            const isStopAccept = await isStopAccepting(state.clinicId ?? '', date, frameItem.startAt);
            if (isStopAccept) {
              continue;
            }
            const perPoints = calcReservePoints(frameItem.numberOfPeople, frameItem.points);
            const isReserveAble = await isReserveAvailable(state.clinicId, date, frameItem.startAt, perPoints);
            if (isReserveAble) {
              baseReservationEvents.push(getBaseEvent(frameItem, date))
            }
          }
        }
      } else {
        noneEvents.push(getNoneEvent(date));
      }
    }
    
    return [ ...dayReservationEvents, ...baseReservationEvents, ...noneEvents ];
  };
  
  /**
   * カレンダーのイベントクリック時の処理
   */
  const onClickEvent = useCallback((eventId: string) => {
    const beforeTappedEventId = state.tapCalendarEvent;
    const currentEvents = [ ...events ];
    if (eventId !== '') {
      const tapEventIndex = currentEvents.findIndex((el) => el.id === eventId);
      if (tapEventIndex !== -1) {
        const tmp = currentEvents[tapEventIndex];
        tmp.color = '#bc8f8f';
        tmp.backgroundColor = '#27414f';
        tmp.borderColor = '#27414f';
        tmp.title = '選択中'
        currentEvents.splice(tapEventIndex, 1, tmp);
      }
      if (beforeTappedEventId !== '') {
        const beforeTapEventIndex = currentEvents.findIndex((el) => el.id === state.tapCalendarEvent);
        if (beforeTapEventIndex !== -1) {
          const tmp = currentEvents[beforeTapEventIndex];
          tmp.title = '';
          tmp.color = '#9FDAF8';
          tmp.backgroundColor = '#9FDAF8';
          tmp.borderColor = '#9FDAF8';
          currentEvents.splice(beforeTapEventIndex, 1, tmp);
        }
      }
      setTapEventId(eventId);
      setEvents(currentEvents);
    }
  }, [ events, setTapEventId, state.tapCalendarEvent ])
  
  /**
   * カレンダーに表示する予約枠イベントの取得Mutation
   */
  const loadEventsMutate = useMutation(
    (val: { start: Date, end: Date, hidden: number[] }) => getReservationEvents(val.start, val.end, val.hidden),
    {
      onSuccess: (data) => {
        // console.log(`---END--- getReservationEvents ${new Date().getTime().toString()}`);
        const currentEvents = [ ...data ];
        const beforeTappedEventId = state.tapCalendarEvent;
        if (beforeTappedEventId !== '') {
          const beforeTapEventIndex = currentEvents.findIndex((el) => el.id === state.tapCalendarEvent);
          if (beforeTapEventIndex !== -1) {
            const tmp = currentEvents[beforeTapEventIndex];
            tmp.color = '#bc8f8f';
            tmp.backgroundColor = '#27414f';
            tmp.borderColor = '#27414f';
            tmp.title = '選択中'
            currentEvents.splice(beforeTapEventIndex, 1, tmp);
          }
        }
        setEvents(currentEvents)
        setIsEventLoading(false)
      }
    }
  )
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, dow: number) => {
    const tmp = [ ...hiddenDayOfWeek ];
    if (event.target.checked) {
      const index = tmp.indexOf(dow, 0);
      if (index > -1) {
        tmp.splice(index, 1);
        setHiddenDayOfWeek(tmp)
      }
    } else {
      tmp.push(dow)
      setHiddenDayOfWeek(tmp)
    }
    if (!currentDateArg) {
      return;
    }
    setIsEventLoading(true)
    loadEventsMutate.mutate({ start: currentDateArg.start, end: currentDateArg.end, hidden: tmp })
  };
  
  return (
    <>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} xl={8} my={2}>
          <Stack spacing={4}>
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="body1" fontWeight="bold">曜日で絞り込み</Typography>
              <FormGroup row style={{ display: 'block' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 1);
                      }}
                    />
                  }
                  label="月"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 2);
                      }}
                    />
                  }
                  label="火"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 3);
                      }}
                    />
                  }
                  label="水"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 4);
                      }}
                    />
                  }
                  label="木"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 5);
                      }}
                    />
                  }
                  label="金"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 6);
                      }}
                    />
                  }
                  label="土"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        handleChange(event, 7);
                      }}
                    />
                  }
                  label="日"
                />
              </FormGroup>
            </Stack>
            <Box style={{ overflow: 'auto', }}>
              <Box style={{ minWidth: '480px' }}>
                <FullCalendar
                  plugins={[ listPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ]}
                  initialView="listMonth"
                  eventDisplay="block"
                  locales={allLocales}
                  locale="ja"
                  height='auto'
                  titleFormat={{
                    year: 'numeric',
                    month: 'short'
                  }}
                  allDaySlot={false}
                  eventTimeFormat={{ hour: "2-digit", minute: "2-digit" }}
                  slotLabelFormat={[ { hour: "2-digit", minute: "2-digit" } ]}
                  slotDuration="00:15:00"
                  slotMinTime={slotMinTime}
                  slotMaxTime={slotMaxTime}
                  firstDay={clinicInfo?.calendarStart ?? 0}
                  headerToolbar={{
                    start: 'today prev',
                    center: 'title',
                    end: 'next listMonth,dayGridMonth,timeGridWeek'
                  }}
                  initialDate={new Date()}
                  events={events}
                  buttonText={{
                    today: '今日',
                    month: '月',
                    week: '週',
                    day: '日',
                    listMonth: 'リスト',
                  }}
                  datesSet={(arg) => {
                    setCurrentDateArg(arg)
                    setIsEventLoading(true)
                    loadEventsMutate.mutate({ start: arg.start, end: arg.end, hidden: hiddenDayOfWeek });
                  }}
                  eventClick={
                    (arg) => {
                      if (state.tapCalendarEvent === arg.event.id) {
                        return;
                      }
                      const timeFormat = 'HH:mm';
                      if (!arg.event.start || !arg.event.end) {
                        return;
                      }
                      onChangeReservationDate(
                        date2string(arg.event.start),
                        string2time(format(arg.event.start, timeFormat)),
                        string2time(format(arg.event.end, timeFormat)),
                      );
                      onClickEvent(arg.event.id);
                    }
                  }
                  dayHeaderContent={
                    (args) => {
                      // console.log(args.view.type);
                      if (args.view.type === "listMonth") {
                        return format(
                          args.date,
                          "MM/dd (EEE)",
                          {
                            locale: ja,
                          })
                      }
                      return format(
                        args.date,
                        "d(EEE)",
                        {
                          locale: ja,
                        })
                    }
                  }
                  lazyFetching
                />
              </Box>
            </Box>
            {state.prevStep !== 3 ?
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6}>
                  <Button variant='contained' sx={{
                    py: '8px',
                    px: {
                      xs: "10px",
                      sm: "24px"
                    }, fontSize: { xs: '14px', sm: '20px' }
                  }}
                          color="secondary"
                          onClick={() => onChangeActiveStep(0)}
                          startIcon={<ArrowBackIcon />}>前のステップへ</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant='contained' sx={{
                    py: '8px', fontWeight: 'bold',
                    px: {
                      xs: "10px",
                      sm: "24px"
                    }, fontSize: { xs: '14px', sm: '20px' }
                  }}
                          onClick={() => {
                            onClickNextStep()
                          }}
                          endIcon={<ArrowForwardIcon />}>次のステップへ</Button>
                </Grid>
              </Grid>
              :
              <Grid container mb={2} alignItems="center" justifyContent="center">
                <Grid item>
                  <Button variant='contained'
                          sx={{ px: '24px', py: '8px', fontWeight: 'bold', fontSize: { xs: '14px', sm: '20px' } }}
                          onClick={() => onClickNextStep()}
                          endIcon={<ArrowForwardIcon />}>確認ステップへ戻る</Button>
                </Grid>
              </Grid>
            }
          </Stack>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isEventLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default DateTimeInputStep;
