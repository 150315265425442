import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import InputForm from "../../components/InputForm/InputForm";
import InputFormLabel from "../../components/InputForm/InputFormLabel";
import usePatientIDTabContentState from "./usePatientIDTabContentState";
import ReservationSearchResultPage from "./ReservationSearchResultPage";
import IClinicInformation from "../../interfaces/IClinicInformation";

export type PatientIDTabContentProps = {
  clinicInfo: IClinicInformation;
}

/**
 *
 * @param clinicInfo
 * @constructor
 * @group Components
 * @category features/patient
 */
const PatientIDTabContent: FC<PatientIDTabContentProps> = ({ clinicInfo }) => {
  const {
    patientId,
    onChangePatientId,
    error,
    searchPatientIdMutation,
    reserves
  } = usePatientIDTabContentState(clinicInfo.id);
  
  const overTabletContents = <Stack direction='column' spacing={4}>
    <Typography variant='body1'>
      予約時に入力した診察券番号から予約情報の照会を行います。
    </Typography>
    <Grid container direction='row' justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={3} sm={3} md={2} lg={2}>
        <InputFormLabel label="診察券番号" />
      </Grid>
      <Grid item xs={9} sm={7} md={6} lg={4}>
        <InputForm
          label='診察券番号'
          value={patientId}
          onChange={onChangePatientId}
          error={error}
        />
      </Grid>
    </Grid>
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <LoadingButton
          loading={searchPatientIdMutation.isLoading}
          loadingIndicator='照会中...'
          variant="contained"
          sx={{
            borderRadius: '24px',
            color: '#FFFFFF',
            elevation: 3,
            py: '8px',
            width: '100%'
          }}
          onClick={() => {
            searchPatientIdMutation.mutate();
          }}
        >
          予約照会する
        </LoadingButton>
      </Grid>
    </Grid>
    {
      reserves ?
        <ReservationSearchResultPage reserves={reserves} />
        : null
    }
  </Stack>;
  
  const mobileContents = <Stack direction='column' spacing={2}>
    <Typography variant='body1'>
      予約時に入力した診察券番号から予約情報の照会を行います。
    </Typography>
    <Stack direction='column' justifyContent='center' alignItems='center'>
      <InputFormLabel label="診察券番号" />
      <InputForm
        label='診察券番号'
        value={patientId}
        onChange={onChangePatientId}
        error={error}
      />
    </Stack>
    
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <LoadingButton
          loading={searchPatientIdMutation.isLoading}
          loadingIndicator='照会中...'
          variant="contained"
          sx={{
            borderRadius: '24px',
            color: '#FFFFFF',
            elevation: 3,
            py: '8px',
            width: '100%'
          }}
          onClick={() => {
            searchPatientIdMutation.mutate();
          }}
        >
          予約照会する
        </LoadingButton>
      </Grid>
    </Grid>
    {
      reserves ?
        <ReservationSearchResultPage reserves={reserves} />
        : null
    }
  </Stack>;
  
  return <>
    <Box sx={{
      display: {
        xs: 'none',
        sm: 'block',
      }
    }}>
      {overTabletContents}
    </Box>
    
    <Box sx={{
      display: {
        xs: 'block',
        sm: 'none',
      }
    }}>
      {mobileContents}
    </Box>
  </>
};

export default PatientIDTabContent;