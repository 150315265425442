import { doc, getDoc, collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import IClinicInformation, { clinicInformationConverter } from "../interfaces/IClinicInformation";
import { firestore, functions } from "../firebase";
import IReserveStatus, { reserveStatusConverter } from "../interfaces/IReserveStatus";

/**
 * 指定したClinic IDのクリニック基本情報を取得する。
 * @param clinicId クリニックのユーザーID
 * @return {Promise<IClinicInformation | null>} データが見つかればクリニック基本情報が返る。
 * @category repositories
 */
export const getClinicInformation = async (clinicId: string): Promise<IClinicInformation | null> => {
  if (clinicId === '') {
    return null;
  }
  const docRef = doc(firestore, 'clinics', clinicId).withConverter(clinicInformationConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
}

/**
 * 指定したClinic IDの現在日時の予約枠の予約状況を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 日付
 * @return {Promise<IClinicInformation | null>} データが見つかれば予約状況が返る。
 * @category repositories
 */
export const getCurrentClinicStatus = async (clinicId: string, date: Date): Promise<IReserveStatus | null> => {
  if (clinicId === '') {
    return null;
  }
  const zeroDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const col = collection(firestore, "clinics", clinicId, "status").withConverter(reserveStatusConverter)
  const q = query(
    col,
    where("date", "==", zeroDate.getTime()),
    orderBy("startHour", "asc"), orderBy("startMinute", "asc")
  );
  const snapshot = await getDocs(q);
  if (snapshot.docs.length <= 0) {
    return null;
  }
  for (let i = 0; i < snapshot.docs.length; i += 1) {
    const document = snapshot.docs[i];
    const data = document.data();
    if (data.endAt >= date.getTime()) {
      return data;
    }
  }
  return null;
}

export const isEnableLineConnection = async (clinicId: string): Promise<boolean> => {
  if (clinicId === "") {
    return false;
  }
  const func = httpsCallable<{ clinicId: string }, boolean>(
    functions,
    'isEnableLineConnection',
  )
  const res = await func({ clinicId });
  return res.data;
}