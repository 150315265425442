/** LINE連携時の結果ステータス */
export type LineLinkStatus =
  "success" |
  "param-error" |
  "already-linked" |
  "failed-get-access-token" |
  "failed-get-line-user-id" |
  "failed-get-friend-status" |
  "not-added-friend" |
  "failed-get-channel-access-token" |
  "not-set-notification-setting" |
  "not-enable-notification" |
  string;

/** LINE連携時の結果ステータスに対応するメッセージ */
export const lineLinkStatusMessage = (status: LineLinkStatus) => {
  switch (status) {
    case "success":
      return "LINE通知設定済みです。"
    case "param-error":
      return "LINE連携に失敗しました。(Param Error)"
    case "already-linked":
      return "LINE通知設定済みです。"
    case "failed-get-access-token":
      return "連携に必要な情報の取得に失敗しました。(Error Code: 201)"
    case "failed-get-line-user-id":
      return "連携に必要な情報の取得に失敗しました。(Error Code: 202)"
    case "failed-get-friend-status":
      return "連携に必要な情報の取得に失敗しました。(Error Code: 203)"
    case "not-added-friend":
      return "友達追加されていない為、通知を送信できません。"
    case "failed-get-channel-access-token":
      return "連携に必要な情報の取得に失敗しました。(Error Code: 204)"
    case "not-set-notification-setting":
      return "連携に必要な情報の取得に失敗しました。(Error Code: 205)"
    case "not-enable-notification":
      return "通知が有効に設定されていません。"
    default:
      return "不明なエラーが発生しました。"
  }
}