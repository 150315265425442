import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import IReservationInformation from "../../interfaces/IReservationInformation";
import { validateRequired } from "../../utils/validator";
import {
  getReservationInformationByPatientId,
} from "../../repositories/reserveRepository";

/**
 *
 * @param clinicId
 * @group Components
 * @category features/patient
 */
const usePatientIDTabContentState = (clinicId: string) => {
  const [ patientId, setPatientId ] = useState("")
  const [ error, setError ] = useState<string | undefined>(undefined)
  const [ reserves, setReserves ] = useState<IReservationInformation[] | undefined>(undefined)
  
  const onChangePatientId = useCallback((phoneStr: string) => {
    setPatientId(phoneStr)
  }, []);
  
  const searchPatientIdMutation = useMutation(
    async () => {
      const errorRaw = validateRequired(patientId);
      if (typeof errorRaw === "string") {
        setError(errorRaw);
        return;
      }
      const results = await getReservationInformationByPatientId(clinicId, patientId)
      setReserves(results);
    },
  )
  
  return { patientId, onChangePatientId, error, reserves, searchPatientIdMutation }
}

export default usePatientIDTabContentState;