import { FC } from "react";
import { Stack, Typography } from "@mui/material";

export type InputFormLabelProps = {
  /** ラベル文字列 */
  label: string,
  /** 必須項目かどうか */
  isRequire?: boolean,
}

/**
 * 入力欄ラベルコンポーネント
 * @param label ラベル文字列
 * @param isRequire 必須項目かどうか
 * @constructor
 * @group Components
 * @category components
 */
const InputFormLabel: FC<InputFormLabelProps> = ({ label, isRequire = false }) =>
  <Stack direction='row' justifyContent="start" alignItems="center">
    <Typography variant='body1' fontWeight="bold" textAlign='start' fontSize="20px">
      {label}
    </Typography>
    {isRequire
      ? <Typography variant='caption' component='div' textAlign='center'
                    sx={{
                      border: 1, fontSize: "12px", lineHeight: "20px", height: "18px",
                      borderRadius: "4px", ml: "4px", px: "4px", color: 'red',
                    }}>
        必須
      </Typography>
      : null
    }
  </Stack>

export default InputFormLabel;