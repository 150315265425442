import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import useReservationPageState from "./useReservationPageState";
import { genderIndex2string, time2string } from "../../utils/converter";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const ConfirmStep = () => {
  const { state, isLoading, onClickReserve, onChangeActiveStep } = useReservationPageState();
  
  const dateFormat = 'yyyy年M月d日(E)';
  
  const overTabletContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} xl={10} my={2}>
      <Stack spacing={4}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' fontWeight='bold' textAlign='start'>
              受付内容
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color='inherit'
              startIcon={<PlayCircleFilledIcon />}
              sx={{ px: '16px', py: '4px' }}
              onClick={() => onChangeActiveStep(0)}
            >
              受付内容を変更する
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent='start' alignItems='center'>
          <Grid item xs={12}>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    当医院の受診
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {state.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                  </TableCell>
                </TableRow>
                {state.hasPatientIdentification ? <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    診察券番号
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {state.patientIdentification}
                  </TableCell>
                </TableRow> : null}
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    症状(自由入力)
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    <Typography variant='caption' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                      {state.reservationDetail}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' fontWeight='bold' textAlign='start'>
              診察日時
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color='inherit'
              startIcon={<PlayCircleFilledIcon />}
              sx={{ px: '16px', py: '4px' }}
              onClick={() => onChangeActiveStep(1)}
            >
              診察日時を変更する
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent='start' alignItems='center'>
          <Grid item xs={12}>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    診察日
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {format(new Date(state.reservationDate), dateFormat, { locale: ja })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    診察時間枠
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {time2string(state.startAt)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' fontWeight='bold' textAlign='start'>
              基本情報
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color='inherit'
              startIcon={<PlayCircleFilledIcon />}
              sx={{ px: '16px', py: '4px' }}
              onClick={() => onChangeActiveStep(2)}
            >
              基本情報を変更する
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent='start' alignItems='center'>
          <Grid item xs={12}>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    お名前
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {state.examineeFirstName} {state.examineeLastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    よみがな
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {state.examineeFirstKana} {state.examineeLastKana}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {state.examineeEmail}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    電話番号
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {state.examineePhone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    性別
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {genderIndex2string(state.examineeGender)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    生年月日
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {format(new Date(state.examineeBirthday), 'yyyy年M月d日', { locale: ja })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid container mb={2} alignItems="center" justifyContent="center">
          <Grid item xs={10}>
            <Typography variant='caption' textAlign='center'>
              <Link to='/'>coMect会員規約・受付・予約サービス利用規約、個人情報の取り扱い</Link>についてをご確認のうえ、同意された場合はお進みください。
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb={2} alignItems="center" justifyContent="space-between">
          <Grid item>
            <Button variant='contained' sx={{ px: '24px', py: '8px', fontSize: '20px' }}
                    color='secondary'
                    onClick={() => onChangeActiveStep(2)}
                    startIcon={<ArrowBackIcon />}>前のステップへ</Button>
          </Grid>
          <Grid item>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{
                borderRadius: '24px',
                elevation: 3,
                px: '24px', py: '8px',
              }}
              loadingIndicator="読込中..."
              loading={isLoading}
              startIcon={<CheckCircleOutlineIcon fontSize="large" sx={{ fontSize: 80 }} />}
              onClick={onClickReserve}
            ><Stack>
              <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                上記内容に同意したうえで
              </Typography>
              <Typography variant='subtitle1' fontWeight='bold' textAlign='center' color='white' fontSize='20px'>
                受付・予約を確定する
              </Typography>
            </Stack>
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  </Grid>;
  
  const mobileContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12}>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Stack direction='column' spacing={1}>
          <Typography variant='h5' fontWeight='bold' textAlign='center'>
            受付内容
          </Typography>
          
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant='body2' fontWeight="bold">
                    診察券
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {state.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                  </Typography>
                </TableCell>
              </TableRow>
              {state.hasPatientIdentification ? <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    診察券番号
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {state.patientIdentification}
                  </Typography>
                </TableCell>
              </TableRow> : null}
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    症状(自由入力)
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                    {state.reservationDetail}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
        
        <Button
          variant="outlined"
          color='inherit'
          startIcon={<PlayCircleFilledIcon />}
          sx={{ px: '16px', py: '4px' }}
          onClick={() => onChangeActiveStep(0)}
        >
          受付内容を変更する
        </Button>
        
        <Stack direction='column' spacing={1}>
          <Typography variant='h5' fontWeight='bold' textAlign='center' mt={4}>
            診察日時
          </Typography>
          
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant='body2' fontWeight="bold">
                    診察日
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {format(new Date(state.reservationDate), dateFormat, { locale: ja })}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    診察時間枠
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {time2string(state.startAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
        
        <Button
          variant="outlined"
          color='inherit'
          startIcon={<PlayCircleFilledIcon />}
          sx={{ px: '16px', py: '4px' }}
          onClick={() => onChangeActiveStep(1)}
        >
          診察日時を変更する
        </Button>
        
        <Stack direction='column' spacing={1}>
          <Typography variant='h5' fontWeight='bold' textAlign='center' mt={4}>
            基本情報
          </Typography>
          
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant='body2' fontWeight="bold">
                    お名前
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {state.examineeFirstName}&nbsp;{state.examineeLastName}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    よみがな
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {state.examineeFirstKana} {state.examineeLastKana}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    メールアドレス
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {state.examineeEmail}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    電話番号
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {state.examineePhone}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    性別
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {genderIndex2string(state.examineeGender)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Typography variant='body2' fontWeight="bold">
                    生年月日
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {format(new Date(state.examineeBirthday), 'yyyy年M月d日', { locale: ja })}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
        
        <Button
          variant="outlined"
          color='inherit'
          startIcon={<PlayCircleFilledIcon />}
          sx={{ px: '16px', py: '4px' }}
          onClick={() => onChangeActiveStep(2)}
        >
          基本情報を変更する
        </Button>
        
        <Stack direction='column' spacing={0}>
          <Typography variant='caption' textAlign='center' mt={4}>
            <Link to='/'>coMect会員規約・受付・予約サービス利用規約、個人情報の取り扱い</Link>についてをご確認のうえ、同意された場合はお進みください。
          </Typography>
        </Stack>
        
        <Grid container alignItems="center" justifyContent="space-between" mt={4}>
          <Grid item xs={12} sm={6} my={2}>
            <Button variant='contained' sx={{ px: '24px', py: '8px', fontSize: '14px' }}
                    color='secondary'
                    onClick={() => onChangeActiveStep(2)}
                    startIcon={<ArrowBackIcon />}>前のステップへ</Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{
                borderRadius: '24px',
                elevation: 3,
                px: '24px', py: '8px',
              }}
              loadingIndicator="読込中..."
              loading={isLoading}
              startIcon={<CheckCircleOutlineIcon fontSize="large" sx={{ fontSize: 80 }} />}
              onClick={onClickReserve}
            ><Stack>
              <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                上記内容に同意したうえで
              </Typography>
              <Typography variant='subtitle1' fontWeight='bold' textAlign='center' color='white' fontSize='20px'>
                受付・予約を確定する
              </Typography>
            </Stack>
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  </Grid>;
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default ConfirmStep;