import { FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { ClinicStatus } from "../types/ClinicStatus";

/**
 * クリニック基本情報を表現するインターフェース
 */
export default interface IClinicInformation {
  /** クリニックID */
  id: string,
  /** クリニック名 */
  clinicName: string,
  /** 郵便番号 */
  postalCode: string,
  /** 住所 */
  address: string,
  /** 電話番号 */
  phoneNumber: string,
  /** 診療科目 */
  departments: string,
  /** 自由入力 */
  freeInputs: string,
  /** 予約可能期間 */
  reservationPeriod: string,
  /** 当日予約可能か */
  isAvailableSameDayReserve: boolean,
  /** 当日予約の受付締切 */
  sameDayReservePeriod: string,
  /** 日曜日スタート: 0, 月曜日スタート: 1 */
  calendarStart: number,
  /** メールアドレス */
  email?: string,
  /** 予約日時 */
  createdAt?: number,
  /** クリニックアカウントのステータス */
  clinicStatus: ClinicStatus,
  /** 未設定かどうか */
  isInitialized: boolean,
}

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、クリニック基本情報の型を変換する。
 */
export const clinicInformationConverter: FirestoreDataConverter<IClinicInformation> = {
  toFirestore: (clinicInfo: IClinicInformation): DocumentData => ({
    clinicName: clinicInfo.clinicName,
    postalCode: clinicInfo.postalCode,
    address: clinicInfo.address,
    phoneNumber: clinicInfo.phoneNumber,
    departments: clinicInfo.departments,
    freeInputs: clinicInfo.freeInputs,
    reservationPeriod: clinicInfo.reservationPeriod,
    isAvailableSameDayReserve: clinicInfo.isAvailableSameDayReserve,
    sameDayReservePeriod: clinicInfo.sameDayReservePeriod,
    calendarStart: clinicInfo.calendarStart,
    email: clinicInfo.email,
    createdAt: clinicInfo.createdAt,
    clinicStatus: clinicInfo.clinicStatus,
    isInitialized: clinicInfo.isInitialized,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): IClinicInformation => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      clinicName: data.clinicName as string,
      postalCode: data.postalCode as string,
      address: data.address as string,
      phoneNumber: data.phoneNumber as string,
      departments: data.departments as string,
      freeInputs: data.freeInputs as string,
      reservationPeriod: data.reservationPeriod as string,
      isAvailableSameDayReserve: data.isAvailableSameDayReserve as boolean,
      sameDayReservePeriod: data.sameDayReservePeriod as string,
      calendarStart: data.calendarStart as number,
      email: data.email as string,
      createdAt: data.createdAt as number,
      clinicStatus: data.clinicStatus as ClinicStatus,
      isInitialized: data.isInitialized as boolean,
    } as IClinicInformation;
  },
};