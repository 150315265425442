/**
 * 指定開始日から指定終了日までの期間のDateListを返す。
 * @param firstDate 指定開始日
 * @param lastDate 指定終了日
 * @return {Date[]} 指定期間のDateListが返る。
 * @category utils
 */
export const getPeriodDateList = (firstDate: Date, lastDate: Date): Date[] => {
  const first = new Date(firstDate);
  const last = new Date(lastDate);
  first.setHours(0, 0, 0, 0);
  last.setHours(0, 0, 0, 0);
  const dateList: Date[] = [];
  let isContinue = true;
  dateList.push(new Date(first));
  while (isContinue) {
    first.setDate(first.getDate() + 1);
    if (first >= last) {
      dateList.push(last);
      isContinue = false;
    } else {
      dateList.push(new Date(first));
    }
  }
  return dateList;
};

/**
 * 指定日の前の日のDateを返す。
 * @param date 指定日
 * @return {Date} 前の日のDateが返る。
 * @category utils
 */
export const getPrevDate = (date: Date): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - 1);
  return result;
};
