import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { addDays } from "date-fns";
import { firestore } from "../firebase";
import IReservationInformation, { reservationInformationConverter } from "../interfaces/IReservationInformation";
import { date2epoch } from "../utils/converter";
import Time from "../types/Time";

/**
 * 指定したReservation IDの診察予約情報を取得する。
 * @param reservationId 診察予約ID
 * @return {Promise<IReservationInformation | undefined>} データが見つかれば診察予約情報が返る。
 * @category repositories
 */
export const getReservationInformation = async (reservationId: string): Promise<IReservationInformation | undefined> => {
  if (reservationId === '') {
    return undefined;
  }
  const docRef = doc(firestore, 'reserves', reservationId).withConverter(reservationInformationConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return undefined;
  }
  return snapshot.data();
};

/**
 * 指定した予約枠に対する診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 予約枠の日付
 * @param startAt 予約枠の開始時刻
 * @return {Promise<IReservationInformation[]>} 指定した予約枠に対する診察予約一覧が返る。
 * @category repositories
 */
export const getReservationsByReserveFrame = async (clinicId: string, date: Date, startAt: Time): Promise<IReservationInformation[]> => {
  const reservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    reservesCol,
    where('clinicId', '==', clinicId),
    where('reservationDate', '==', date2epoch(date)),
    where('startHour', '==', startAt.hour),
    where('startMinute', '==', startAt.minute),
    orderBy('number')
  );
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs.map((val) => val.data());
  }
  return [];
}

/**
 * 診察予約一覧を開始時刻順にソートする。
 * @param reservationInformationList
 * @return {IReservationInformation[]} ソート後の診察予約一覧が返る。
 * @category repositories
 */
const sortReservationsByFrame = (reservationInformationList: IReservationInformation[]): IReservationInformation[] => {
  const ret = [ ...reservationInformationList ]
  ret.sort((a, b) => {
    if (a.reservationDate < b.reservationDate) {
      return -1;
    }
    if (a.reservationDate === b.reservationDate) {
      if (a.startHour < b.startHour) {
        return -1;
      }
      if (a.startHour === b.startHour) {
        if (a.startMinute < b.startMinute) {
          return -1;
        }
        if (a.startMinute === b.startMinute) {
          return 0;
        }
        return 1;
      }
      return 1;
    }
    return 1;
  })
  return ret;
}

/**
 * 電話番号を利用して診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param phone 電話番号
 * @return {Promise<IReservationInformation[]>} 診察予約一覧が返る。
 * @category repositories
 */
export const getReservationInformationByPhone = async (clinicId: string, phone: string): Promise<IReservationInformation[]> => {
  const ReservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    ReservesCol,
    where("clinicId", "==", clinicId),
    where('examineePhone', '==', phone),
    where("reservationDate", ">", date2epoch(addDays(new Date(), -1)))
  );
  const snapshot = await getDocs(q);
  const reservationInformationList: IReservationInformation[] = [];
  if (snapshot.docs.length === 0) {
    return reservationInformationList;
  }
  snapshot.docs.forEach(itemDoc => {
    const reservationInformation = itemDoc.data();
    reservationInformationList.push(reservationInformation);
  });
  return sortReservationsByFrame(reservationInformationList);
};

/**
 * 診察券番号を利用して診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param patientId 診察券番号
 * @return {Promise<IReservationInformation[]>} 診察予約一覧が返る。
 * @category repositories
 */
export const getReservationInformationByPatientId = async (clinicId: string, patientId: string): Promise<IReservationInformation[]> => {
  const ReservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    ReservesCol,
    where("clinicId", "==", clinicId),
    where('patientIdentification', '==', patientId),
    where("reservationDate", ">", date2epoch(addDays(new Date(), -1)))
  );
  const snapshot = await getDocs(q);
  const reservationInformationList: IReservationInformation[] = [];
  if (snapshot.docs.length === 0) {
    return reservationInformationList;
  }
  snapshot.docs.forEach(itemDoc => {
    const reservationInformation = itemDoc.data();
    reservationInformationList.push(reservationInformation);
  });
  return sortReservationsByFrame(reservationInformationList);
};
