import { FC, Suspense, useCallback } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid, IconButton,
  Stack,
  Table, TableBody, TableCell,
  TableContainer, TableRow,
  Typography
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import RefreshIcon from '@mui/icons-material/Refresh';
import { getClinicInformation, getCurrentClinicStatus } from "../../repositories/clinicRepository";
import { notFoundClinicPath } from "../../urls";

type WaitingStatusContentProps = {
  clinicId: string,
};

const WaitingStatusContentBody: FC<WaitingStatusContentProps> = ({ clinicId }) => {
  const navigate = useNavigate();
  
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', clinicId ],
    () => getClinicInformation(clinicId),
  );
  
  const { data: statusData, refetch } = useQuery(
    [ 'getStatus', clinicId ],
    () => getCurrentClinicStatus(clinicId, new Date())
  )
  
  const onClickRefresh = useCallback(() => {
    void refetch();
  }, [ refetch ])
  
  const handleClickGoToReservation = useCallback(() => {
    navigate('reservation')
  }, [ navigate ]);
  
  if (!clinicInfo) {
    navigate(notFoundClinicPath);
    return null;
  }
  
  return <Grid container>
    <Grid item xs={12}>
      <Stack direction='column' mb={2}>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Typography variant='h5' fontWeight='bold'>
            現在の診察待ち状況
          </Typography>
          <IconButton onClick={onClickRefresh}>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <Typography variant='caption' color='text.secondary'>
          ※ 実際の受付状況とは異なる場合がございます。
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Stack justifyContent='center' alignItems='center' spacing={2}>
                {
                  statusData ? <TableContainer>
                      <Table sx={{ margin: 'auto' }}>
                        <TableBody>
                          <TableRow>
                            <TableCell component='th'>
                              診察中の番号
                            </TableCell>
                            <TableCell>
                              <Typography variant='body2'>
                                {statusData.currentNum !== 0 ? statusData.currentNum.toString().padStart(3, '0') : '---'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component='th'>
                              診察待ち人数
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {(statusData.currentPoints - statusData.calledPoints) / statusData.pointPerPeople} 人
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component='th'>
                              不在者数
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {statusData.absenteeNum} 人
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    :
                    <Typography variant='h6'>現在、診察待ち状況は更新されていません。</Typography>
                }
                <Button
                  variant='contained'
                  fullWidth
                  sx={{
                    borderRadius: '24px',
                    color: '#FFFFFF',
                    elevation: 3,
                    py: '8px',
                  }}
                  onClick={handleClickGoToReservation}
                >
                  予約へ
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
};

/**
 *
 * @param clinicId
 * @constructor
 * @group Components
 * @category features/patient
 */
const WaitingStatusContent: FC<WaitingStatusContentProps> = ({ clinicId }) => <Suspense
  fallback={<CircularProgress />}>
  <WaitingStatusContentBody clinicId={clinicId} />
</Suspense>

export default WaitingStatusContent;