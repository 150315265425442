import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

/**
 * 予約ステータス情報を表現するインターフェース
 */
export default interface IReserveStatus {
  /** 次の呼び出し番号 */
  currentNum: number;
  /**
   * 現在の最新（最後に予約された予約）の番号
   * 次に予約された際は、この数値 + 1の番号を付与する。
   */
  maxNum: number;
  /** 予約枠のトータルポイント */
  totalPoints: number;
  /** 予約ステータスのポイント集計 */
  currentPoints: number;
  /** 呼び出し済みステータスのポイント集計 */
  calledPoints: number;
  /** 予約枠の開始時刻 */
  startAt: number;
  /** 予約枠の終了時刻 */
  endAt: number;
  /** 不在ステータスのポイント集計 */
  absenteeNum: number;
  /** 予約枠の日付 */
  date: number;
  /** 予約枠の終了時刻 */
  pointPerPeople: number;
  /** 予約データの件数 */
  remainDataNum: number;
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、予約ステータス情報の型を変換する。
 */
export const reserveStatusConverter: FirestoreDataConverter<IReserveStatus> = {
  toFirestore: (reserveStatus: IReserveStatus): DocumentData => ({
    currentNum: reserveStatus.currentNum,
    maxNum: reserveStatus.maxNum,
    totalPoints: reserveStatus.totalPoints,
    currentPoints: reserveStatus.currentPoints,
    calledPoints: reserveStatus.calledPoints,
    startAt: reserveStatus.startAt,
    endAt: reserveStatus.endAt,
    absenteeNum: reserveStatus.absenteeNum,
    date: reserveStatus.date,
    pointPerPeople: reserveStatus.pointPerPeople,
    remainDataNum: reserveStatus.remainDataNum,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): IReserveStatus => {
    const data = snapshot.data(options);
    return {
      currentNum: data.currentNum as number,
      maxNum: data.maxNum as number,
      totalPoints: data.totalPoints as number,
      currentPoints: data.currentPoints as number,
      calledPoints: data.calledPoints as number,
      startAt: data.startAt as number,
      endAt: data.endAt as number,
      absenteeNum: data.absenteeNum as number,
      date: data.date as number,
      pointPerPeople: data.pointPerPeople as number,
      remainDataNum: data.remainDataNum as number,
    } as IReserveStatus;
  },
};