import { FC, Suspense } from "react";
import { useQuery } from "react-query";
import {
  Box, CircularProgress, Grid, Stack, Table, TableBody, TableCell,
  TableContainer, TableRow, Typography
} from "@mui/material";
import { useNavigate } from "react-router";
import { getClinicInformation } from "../../repositories/clinicRepository";
import { postalCodeString } from "../../utils/converter";
import { notFoundClinicPath } from "../../urls";

type ClinicInfoContentType = {
  clinicId: string,
};

const ClinicInfoContentBody: FC<ClinicInfoContentType> = ({ clinicId }) => {
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', clinicId ],
    () => getClinicInformation(clinicId),
  );
  const navigate = useNavigate();
  
  if (!clinicInfo) {
    navigate(notFoundClinicPath);
    return null;
  }
  
  const mobileContents = <Grid container sx={{ padding: "16px", backgroundColor: '#DEEAE9' }}>
    <Grid item xs={12}>
      <Stack direction='column' justifyContent='center' spacing={2}>
        <Typography variant='h4' component='h1' fontWeight='bold'>
          {clinicInfo.clinicName}
        </Typography>
        <Stack direction='column' justifyContent='center' spacing={0}>
          <Typography variant='body1'>
            {`〒 ${postalCodeString(clinicInfo.postalCode)}`}
          </Typography>
          <Typography variant="body1">
            {clinicInfo.address}
          </Typography>
        </Stack>
        <Stack direction='column' justifyContent='center' spacing={0}>
          <Typography variant="body1">
            {`☎ ${clinicInfo.phoneNumber}`}
          </Typography>
        </Stack>
        <Typography variant="body1" textAlign='center'>
          {`診療科目： ${clinicInfo.departments}`}
        </Typography>
        <Stack direction='column' spacing={0}>
          <Typography variant="body1" fontWeight='bold'>
            ― 病院からのお知らせ ―
          </Typography>
          <Typography variant='caption' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
            {clinicInfo.freeInputs}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  </Grid>;
  
  const overTabletContents = <Grid container sx={{ padding: "16px", backgroundColor: '#DEEAE9' }}>
    <Grid item sm={12}>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Typography variant='h4' component='h1' fontWeight='bold'>
          {clinicInfo.clinicName}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component='th'>
                  <Typography variant='body1' fontWeight="bold">
                    所在地
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body1'>
                    {`〒 ${postalCodeString(clinicInfo.postalCode)}`}
                  </Typography>
                  <Typography variant="body1">
                    {clinicInfo.address}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  <Typography variant="body1" fontWeight="bold">
                    電話番号
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {clinicInfo.phoneNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  <Typography variant="body1" fontWeight="bold">
                    診療科目
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {clinicInfo.departments}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  <Typography variant="body1" fontWeight="bold">
                    その他
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body1' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                    {clinicInfo.freeInputs}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Grid>
  </Grid>;
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

/**
 *
 * @param clinicId
 * @constructor
 * @group Components
 * @category features/patient
 */
const ClinicInfoContent: FC<ClinicInfoContentType> = ({ clinicId }) =>
  <Suspense
    fallback={<CircularProgress />}>
    <ClinicInfoContentBody clinicId={clinicId} />
  </Suspense>

export default ClinicInfoContent;