import {
  Box, Stack, Typography
} from "@mui/material";
import PatientPageBase from "../PageBase/PatientPageBase";

/**
 * 何らかのエラー発生時のページ
 * @constructor
 * @group Components
 * @category features/ErrorPage
 */
const ErrorPage = () => <PatientPageBase>
  <Stack direction='column' m={2}>
    <Box mt={2} mb={4}>
      <Typography variant='subtitle1' fontWeight='bold'>
        エラーが発生しました。
      </Typography>
      <Typography variant='body2'>
        しばらくしてからもう一度アクセスしてください。
      </Typography>
    </Box>
  </Stack>
</PatientPageBase>;

export default ErrorPage;