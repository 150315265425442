import { AlertColor } from '@mui/material';
import { useRecoilState } from 'recoil';
import { ReactNode, useCallback } from "react";
import resultAlertStateAtom from "../../recoil/components/ResultAlert";

/**
 * アラート表示制御
 * @group Components
 * @category components
 */
const useResultAlertState = () => {
  const [ state, setState ] = useRecoilState(resultAlertStateAtom);
  
  /**
   * アラートを表示する。
   * @param result アラートのタイプ
   * @param message メッセージ
   */
  const openAlert = useCallback((result: AlertColor, message: ReactNode) => {
    setState((prev) => ({ ...prev, isOpen: true, result, message }))
  }, [ setState ]);
  
  /**
   * アラートを閉じる。
   */
  const closeAlert = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }))
  }, [ setState ]);
  
  return {
    state,
    openAlert,
    closeAlert,
  };
};

export default useResultAlertState;
