import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button,
  Grid, Stack,
  Table,
  TableBody, TableCell, TableRow,
  Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
// import CopyToClipboard from "react-copy-to-clipboard";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { time2string } from "../../utils/converter";
import IReservationInformation from "../../interfaces/IReservationInformation";
// import useResultAlertState from "../../components/ResultAlert/useResultAlertState";

type ReservationListItemContentProps = {
  reservationInfo: IReservationInformation,
}

/**
 *
 * @param reservationInfo
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReservationListItemContent: FC<ReservationListItemContentProps> = ({ reservationInfo }) => {
  // const { openAlert } = useResultAlertState();
  const navigate = useNavigate();
  
  const [ receptorInfoExpanded, setReceptorInfoExpanded ] = useState<boolean>(false);
  // const [ baseInfoExpanded, setBaseInfoExpanded ] = useState<boolean>(false);
  
  const overTabletContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} md={10} lg={7} my={2} sx={{
      borderRadius: '24px',
      backgroundColor: '#fafafa',
      elevation: 8,
      p: "16px"
    }}>
      <Stack spacing={2}>
        <Stack direction='row' spacing={2} alignItems='end'>
          <Typography variant='h5' fontWeight='bold'>
            {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
          </Typography>
          <Typography variant='subtitle1'>
            {`${time2string({
              hour: reservationInfo.startHour,
              minute: reservationInfo.startMinute,
            })}
                        ~
                        ${time2string({
              hour: reservationInfo.endHour,
              minute: reservationInfo.endMinute,
            })}`}
          </Typography>
        </Stack>
        <Box />
        <Accordion expanded={receptorInfoExpanded} onChange={() => setReceptorInfoExpanded(!receptorInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受付・予約情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                {/* <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    予約ID
                  </TableCell>
                  <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                    {reservationInfo.id}
                    <CopyToClipboard text={reservationInfo.id} onCopy={() => {
                      openAlert('success', '予約IDをコピーしました。')
                    }}>
                      <IconButton size="small">
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow> */}
                
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    呼び出し番号
                  </TableCell>
                  <TableCell sx={{ width: "60%", color: "#362f5f" }} align="right">
                    {reservationInfo.number.toString().padStart(3, '0')}
                  </TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    診察日
                  </TableCell>
                  <TableCell sx={{ width: "60%", color: "#362f5f" }} align="right">
                    {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    診察時間枠
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {`${time2string({
                      hour: reservationInfo.startHour,
                      minute: reservationInfo.startMinute,
                    })}
                        ~
                        ${time2string({
                      hour: reservationInfo.endHour,
                      minute: reservationInfo.endMinute,
                    })}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    当医院の受診
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                  </TableCell>
                </TableRow>
                {reservationInfo.hasPatientIdentification ? <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    診察券番号
                  </TableCell>
                  <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                    {reservationInfo.patientIdentification}
                  </TableCell>
                </TableRow> : null}
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                    症状(自由入力)
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right"
                             style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                    {reservationInfo.reservationDetail}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/patient/reserves/?id=${reservationInfo.id}`)
          }}
        >
          詳細はこちら
        </Button>
      </Stack>
    </Grid>
  </Grid>;
  
  const mobileContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} md={10} lg={7} my={2} sx={{
      borderRadius: '24px',
      backgroundColor: '#fafafa',
      elevation: 8,
      p: "16px"
    }}>
      <Stack spacing={2}>
        <Stack direction='column' spacing={0} alignItems='center'>
          <Typography variant='h5' fontWeight='bold'>
            {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
          </Typography>
          <Typography variant='subtitle1'>
            {`${time2string({
              hour: reservationInfo.startHour,
              minute: reservationInfo.startMinute,
            })}
                        ~
                        ${time2string({
              hour: reservationInfo.endHour,
              minute: reservationInfo.endMinute,
            })}`}          </Typography>
        </Stack>
        <Box />
        <Accordion expanded={receptorInfoExpanded} onChange={() => setReceptorInfoExpanded(!receptorInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受付・予約情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
              {/* <Stack direction='column' spacing={0} alignItems='center'>
                <Typography variant='subtitle1' fontWeight='bold'>
                  予約ID
                </Typography>
                <div>
                  {reservationInfo.id}
                  <CopyToClipboard text={reservationInfo.id} onCopy={() => {
                    openAlert('success', '予約IDをコピーしました。')
                  }}>
                    <IconButton size="small">
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </div>
              </Stack> */}
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  呼び出し番号
                </Typography>
                <div>
                  {reservationInfo.number.toString().padStart(3, '0')}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  診察日
                </Typography>
                <div>
                  {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  診察時間枠
                </Typography>
                <div>
                  {`${time2string({
                    hour: reservationInfo.startHour,
                    minute: reservationInfo.startMinute,
                  })}
                        ~
                        ${time2string({
                    hour: reservationInfo.endHour,
                    minute: reservationInfo.endMinute,
                  })}`}
                </div>
              </Stack>
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  診察券
                </Typography>
                <div>
                  {reservationInfo.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                </div>
              </Stack>
              
              {reservationInfo.hasPatientIdentification ?
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    診察券番号
                  </Typography>
                  <div>
                    {reservationInfo.patientIdentification}
                  </div>
                </Stack> : null}
              
              <Stack direction='column' spacing={0}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  症状(自由入力)
                </Typography>
                <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                  {
                    reservationInfo.reservationDetail &&
                    reservationInfo.reservationDetail !== "" ?
                      reservationInfo.reservationDetail
                      : "入力無し"
                  }
                </div>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/patient/reserves/?id=${reservationInfo.id}`)
          }}
        >
          詳細はこちら
        </Button>
      </Stack>
    </Grid>
  </Grid>
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default ReservationListItemContent;