import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { getReservationInformationByPhone } from "../../repositories/reserveRepository";
import IReservationInformation from "../../interfaces/IReservationInformation";
import { validatePhoneNumber } from "../../utils/validator";

/**
 *
 * @param clinicId
 * @group Components
 * @category features/patient
 */
const usePhoneTabContentState = (clinicId: string) => {
  const [ phone, setPhone ] = useState("")
  const [ error, setError ] = useState<string | undefined>(undefined)
  const [ reserves, setReserves ] = useState<IReservationInformation[] | undefined>(undefined)
  
  const onChangePhone = useCallback((phoneStr: string) => {
    setPhone(phoneStr)
  }, []);
  
  const searchPhoneMutation = useMutation(
    async () => {
      const errorRaw = validatePhoneNumber(phone);
      if (typeof errorRaw === "string") {
        setError(errorRaw);
        return;
      }
      const results = await getReservationInformationByPhone(clinicId, phone)
      setReserves(results);
    },
  )
  
  return { phone, onChangePhone, error, reserves, searchPhoneMutation }
};

export default usePhoneTabContentState;