import {
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from "firebase/firestore";
import {
  validateEmail,
  validateHiragana,
  validateIsPastDate,
  validatePhoneNumber,
  validateRequired
} from "../utils/validator";
import { Gender } from "../types/Gender";
import { ReserveStatus } from "../types/ReserveStatus";

/**
 * 予約情報を表現するインターフェース
 */
export default interface IReservationInformation {
  /** 予約ID */
  id: string,
  /** 呼び出し順番号 */
  number: number,
  /** クリニックID */
  clinicId: string,
  /** 姓 */
  examineeFirstName: string,
  /** 名 */
  examineeLastName: string,
  /** せい */
  examineeFirstKana: string,
  /** めい */
  examineeLastKana: string,
  /** メールアドレス */
  examineeEmail: string,
  /** 電話番号 */
  examineePhone: string,
  /** 性別 */
  examineeGender: Gender,
  /** 生年月日 */
  examineeBirthday: number,
  /** 診察券をお持ちかどうか */
  hasPatientIdentification: boolean,
  /** 診察券番号 */
  patientIdentification: string,
  /** 受診内容 */
  reservationDetail: string,
  /** 受診日 */
  reservationDate: number,
  /** 診察予約枠の開始時刻(時間) */
  startHour: number,
  /** 診察予約枠の開始時刻(分) */
  startMinute: number,
  /** 診察予約枠の終了時刻(時間) */
  endHour: number,
  /** 診察予約枠の終了時刻(分) */
  endMinute: number,
  /** 予約時の消化ポイント */
  point: number,
  /** 予約ステータス */
  reserveStatus: ReserveStatus,
  /** 予約日時 */
  createdAt: Date,
  /** LINE通知設定しているかどうか */
  isEnableLineNotify: boolean,
  /** LINEユーザーID */
  lineUserId: string | null,
  /** 日付(エポック) */
  dayOfWeek: number,
}

export type ReservationInformationErrors = {
  examineeFirstName?: string | undefined;
  examineeLastName?: string | undefined;
  examineeFirstKana?: string | undefined;
  examineeLastKana?: string | undefined;
  examineeBirthday?: string | undefined;
  examineeEmail?: string | undefined;
  examineePhone?: string | undefined;
  reservationDetail?: string | undefined;
};

/**
 * 診察予約の基本情報ステップのバリデーション。
 * @return {ReservationInformationErrors | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validateBasicInputStep = (examineeFirstName = '', examineeLastName = '',
  examineeFirstKana = '', examineeLastKana = '', examineeBirthday = '', examineeEmail = '', examineePhone = ''): ReservationInformationErrors | undefined => {
  const errors: ReservationInformationErrors = {
    examineeFirstName: undefined,
    examineeLastName: undefined,
    examineeFirstKana: undefined,
    examineeLastKana: undefined,
    examineeBirthday: undefined,
    examineeEmail: undefined,
    examineePhone: undefined,
  };
  errors.examineeFirstName = validateRequired(examineeFirstName);
  errors.examineeLastName = validateRequired(examineeLastName);
  errors.examineeFirstKana = validateHiragana(examineeFirstKana, true);
  errors.examineeLastKana = validateHiragana(examineeLastKana, true);
  errors.examineeBirthday = validateIsPastDate(examineeBirthday);
  errors.examineeEmail = validateEmail(examineeEmail);
  errors.examineePhone = validatePhoneNumber(examineePhone);
  if (Object.values(errors).findIndex((element) => typeof element === 'string') !== -1) {
    return errors;
  }
  return undefined;
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、診察予約情報の型を変換する。
 */
export const reservationInformationConverter: FirestoreDataConverter<IReservationInformation> = {
  toFirestore: (reservationInfo: IReservationInformation): DocumentData => ({
    clinicId: reservationInfo.clinicId,
    number: reservationInfo.number,
    examineeFirstName: reservationInfo.examineeFirstName,
    examineeLastName: reservationInfo.examineeLastName,
    examineeFirstKana: reservationInfo.examineeFirstKana,
    examineeLastKana: reservationInfo.examineeLastKana,
    examineeEmail: reservationInfo.examineeEmail,
    examineePhone: reservationInfo.examineePhone,
    examineeGender: reservationInfo.examineeGender,
    examineeBirthday: reservationInfo.examineeBirthday,
    hasPatientIdentification: reservationInfo.hasPatientIdentification,
    patientIdentification: reservationInfo.patientIdentification,
    reservationDetail: reservationInfo.reservationDetail,
    reservationDate: reservationInfo.reservationDate,
    startHour: reservationInfo.startHour,
    startMinute: reservationInfo.startMinute,
    endHour: reservationInfo.endHour,
    endMinute: reservationInfo.endMinute,
    point: reservationInfo.point,
    reserveStatus: reservationInfo.reserveStatus,
    createdAt: reservationInfo.createdAt.getTime(),
    isEnableLineNotify: reservationInfo.isEnableLineNotify,
    lineUserId: reservationInfo.lineUserId,
    dayOfWeek: reservationInfo.dayOfWeek,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): IReservationInformation => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      clinicId: data.clinicId as string,
      number: data.number as number,
      examineeFirstName: data.examineeFirstName as string,
      examineeLastName: data.examineeLastName as string,
      examineeFirstKana: data.examineeFirstKana as string,
      examineeLastKana: data.examineeLastKana as string,
      examineeEmail: data.examineeEmail as string,
      examineePhone: data.examineePhone as string,
      examineeGender: data.examineeGender as number,
      examineeBirthday: data.examineeBirthday as number,
      hasPatientIdentification: data.hasPatientIdentification as boolean,
      patientIdentification: data.patientIdentification as string,
      reservationDetail: data.reservationDetail as string,
      reservationDate: data.reservationDate as number,
      startHour: data.startHour as number,
      startMinute: data.startMinute as number,
      endHour: data.endHour as number,
      endMinute: data.endMinute as number,
      point: data.point as number,
      reserveStatus: data.reserveStatus as number,
      createdAt: new Date(data.createdAt as number),
      isEnableLineNotify: data.isEnableLineNotify as boolean,
      lineUserId: data.lineUserId as string,
      dayOfWeek: data.dayOfWeek as number,
    } as IReservationInformation;
  },
};
