import { TextField } from "@mui/material";
import React, { FC } from "react";

export type DateInputFormProps = {
  /** ラベル文字列 */
  label: string;
  /** 現在値 */
  value?: string;
  /** 値が変化した時のコールバック */
  onChange?: (date?: string) => void;
  /** 横幅を広げるかどうか */
  fullWidth?: boolean;
  /** 無効化 */
  disabled?: boolean;
  /** エラー文字列 */
  error?: string | undefined;
};

/**
 * 日付入力フォームコンポーネント
 * @param label 入力欄ラベル
 * @param value 現在値
 * @param onChange 値が変更された時のコールバック
 * @param fullWidth 横幅を最大まで広げるかどうか
 * @param disabled 無効化
 * @param error バリデーションエラー
 * @constructor
 * @group Components
 * @category components
 */
export const DateInputForm: FC<DateInputFormProps> = ({
    label,
    value = undefined,
    onChange = undefined,
    fullWidth = true,
    disabled = false,
    error = undefined
  }) =>
    <TextField
      id="date"
      label={label}
      type={disabled ? undefined : "date"}
      value={value || ''}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(e) => onChange ? onChange(e.target.value) : undefined}
      color="primary"
      error={!!error}
      helperText={error || null}
    />
;