import {
  Grid, Stack, Typography
} from "@mui/material";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const NotFoundContent = () =>
  <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} md={10} lg={7} my={2}>
      <Stack spacing={0}>
        <Typography variant='h6'>
          予約情報が見つかりませんでした。
        </Typography>
        <Typography variant='body2' mb={4}>
          再度、予約照会フォームでお試しください。
        </Typography>
      </Stack>
    </Grid>
  </Grid>

export default NotFoundContent;