import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import LoadingButton from "@mui/lab/LoadingButton";
import { isMobile } from "react-device-detect";
import ReservationDetailContent from "./ReservationDetailContent";
import NotFoundContent from "./NotFoundContent";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { LineLinkStatus, lineLinkStatusMessage } from "../../types/LineLinkStatus";
import { getReservationInformation } from "../../repositories/reserveRepository";
import { getClinicInformation, isEnableLineConnection } from "../../repositories/clinicRepository";
import { functions } from "../../firebase";
import PatientPageBase from "../PageBase/PatientPageBase";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReservationDetailPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { openAlert } = useResultAlertState();
  
  const [ isLinkingLine, setIsLinkingLine ] = useState(false);
  const [ isSendingLineTestMessage, setIsSendingLineTestMessage ] = useState(false)
  const [ lineStatus, setLineStatus ] = useState<LineLinkStatus | undefined>(undefined);
  
  const reservationId = useMemo(() => {
    const param = new URLSearchParams(search)
    return param.get('id')
  }, [ search ]);
  const code = useMemo(() => {
    const param = new URLSearchParams(search)
    return param.get('code')
  }, [ search ]);
  const stateCode = useMemo(() => {
    const param = new URLSearchParams(search)
    return param.get('state')
  }, [ search ]);
  
  const { data: reservationInfo } = useQuery([ 'getReservationInformation', reservationId ?? '' ],
    () => getReservationInformation(reservationId ?? ''),
  );
  
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', reservationInfo?.clinicId ?? "" ],
    () => getClinicInformation(reservationInfo?.clinicId ?? ""),
  );
  
  const { data: isEnableLine } = useQuery([ 'isEnableLineConnection', reservationInfo?.clinicId ?? "" ],
    () => isEnableLineConnection(reservationInfo?.clinicId ?? ""),
  );
  
  useEffect(() => {
    if (reservationInfo) {
      if (reservationInfo.isEnableLineNotify) {
        setLineStatus("already-linked")
      }
    }
  }, [ reservationInfo ])
  
  useEffect(() => {
    if (reservationInfo === null) {
      openAlert("error", "予約情報の照会に失敗しました。")
    } else {
      openAlert("success", "予約情報の照会に成功しました。")
    }
    
    const linkingLine = async () => {
      if (!code) {
        return;
      }
      const func = httpsCallable<{ code: string, reserveId: string }, { result: boolean, data: string | null, code: string | null, error: string | null }>(
        functions,
        'getAccessToken',
      )
      const res = await func({ code, reserveId: reservationId ?? "" })
      if (res.data.error) {
        openAlert("error", lineLinkStatusMessage(res.data.code ?? ""));
        // TODO: Error handling
        setLineStatus(res.data.code ?? "");
        return;
      }
      openAlert('success', 'LINE通知の設定が完了しました。')
      setLineStatus("success")
    };
    
    if (code && stateCode) {
      void linkingLine();
    }
  }, [ code, openAlert, reservationId, reservationInfo, stateCode ]);
  
  const handleClickBackButton = useCallback(() => {
    navigate(`/patient/${clinicInfo?.id ?? ""}`)
  }, [ clinicInfo?.id, navigate ]);
  
  const onClickLinkLine = useCallback(async () => {
    if (!isMobile) {
      openAlert("warning", "スマートフォンから開いてください。")
      return;
    }
    setIsLinkingLine(true)
    const func = httpsCallable<{ id: string, clinicId: string }, { result: boolean, url: string | null, error: string | null }>(
      functions,
      'getLinkUrlForLine',
    )
    const res = await func({ id: reservationId ?? "", clinicId: clinicInfo?.id ?? "" })
    if (!res.data.result) {
      openAlert("error", "LINE連携情報の取得に失敗しました。")
      setIsLinkingLine(false)
      return;
    }
    if (res.data.url) {
      window.open(res.data.url)
      setIsLinkingLine(false)
    }
  }, [ clinicInfo?.id, openAlert, reservationId ]);
  
  const onClickSendLineTestMessage = useCallback(async () => {
    setIsSendingLineTestMessage(true)
    const func = httpsCallable<{ clinicId: string, lineUserId: string }, { result: boolean }>(
      functions,
      'sendTestLineMessage',
    )
    const res = await func({ clinicId: clinicInfo?.id ?? "", lineUserId: reservationInfo?.lineUserId ?? "" })
    if (!res.data.result) {
      setIsSendingLineTestMessage(false)
      openAlert("error", "連携テストメッセージの送信に失敗しました。")
      return
    }
    setIsSendingLineTestMessage(false)
    openAlert("success", "連携テストメッセージを送信しました。")
  }, [ clinicInfo?.id, openAlert, reservationInfo?.lineUserId ])
  
  return <PatientPageBase>
    <Box mx={2} mt={2} mb={4}>
      <Stack direction='column' justifyContent='center' spacing={0} style={{ textAlign: 'center' }}>
        <div style={{ textAlign: 'start' }}>
          <Button variant='outlined' onClick={handleClickBackButton}>
            <ArrowBackIcon />
          </Button>
        </div>
        <Typography variant='h6' fontWeight='bold' mb={2}>
          予約照会結果
        </Typography>
        <Typography variant='caption' fontWeight='bold' color='#c62828'>
          ※ 予約情報の変更やキャンセルがある場合はクリニックへの直接ご連絡をお願いします。
        </Typography>
        <Grid container justifyContent='center' mt={4}>
          <Grid item xs={10} sm={6}>
            {
              lineStatus === "success" || lineStatus === "already-linked" ?
                <Stack direction="column" spacing={1}>
                  <Typography>
                    {lineLinkStatusMessage(lineStatus)}
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    loading={isSendingLineTestMessage}
                    onClick={() => {
                      void onClickSendLineTestMessage();
                    }}
                  >
                    連携確認メッセージを送信
                  </LoadingButton>
                  <LoadingButton
                    variant="text"
                    loading={isLinkingLine}
                    loadingIndicator="読込中..."
                    onClick={() => {
                      void onClickLinkLine();
                    }}>
                    <Typography variant="caption">
                      LINE連携の再設定はこちら
                    </Typography>
                  </LoadingButton>
                </Stack>
                :
                <Stack direction="column">
                  <LoadingButton
                    variant='contained'
                    loading={isLinkingLine}
                    onClick={() => {
                      void onClickLinkLine();
                    }}
                    loadingIndicator="読込中..."
                  >
                    <Stack>
                      <Typography variant='subtitle1' fontWeight='bold' textAlign='center' color='white'>
                        LINE通知の設定
                      </Typography>
                      <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                        はこちらから
                      </Typography>
                    </Stack>
                  </LoadingButton>
                  {
                    lineStatus ? <Typography>{lineStatus ? lineLinkStatusMessage(lineStatus) : ""}</Typography> : null
                  }
                </Stack>
            }
          </Grid>
        </Grid>
      </Stack>
    </Box>
    <Card>
      <CardContent>
        {
          !reservationId || !reservationInfo || !clinicInfo
            ? <NotFoundContent />
            : <ReservationDetailContent reservationInfo={reservationInfo} clinicInfo={clinicInfo} />
        }
      </CardContent>
    </Card>
  </PatientPageBase>
};

export default ReservationDetailPage;