import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button,
  Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography
} from "@mui/material";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { useCallback, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { httpsCallable } from "firebase/functions";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import { isMobile } from 'react-device-detect';
import useReservationPageState from "./useReservationPageState";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { getReservationInformation } from "../../repositories/reserveRepository";
import { functions } from "../../firebase";
import { genderIndex2string, time2string } from "../../utils/converter";
import { isEnableLineConnection } from "../../repositories/clinicRepository";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const CompletedStep = () => {
  const { state, resetState } = useReservationPageState();
  const { openAlert } = useResultAlertState();
  const navigate = useNavigate();
  
  const { data: reservationInfo } = useQuery([ 'getReservationInformation', state.reservationId ],
    () => getReservationInformation(state.reservationId),
  );
  
  const { data: isEnableLine } = useQuery([ 'isEnableLineConnection', reservationInfo?.clinicId ?? "" ],
    () => isEnableLineConnection(reservationInfo?.clinicId ?? ""),
  );
  
  const [ isLinkingLine, setIsLinkingLine ] = useState(false);
  const [ notificationExpanded, setNotificationExpanded ] = useState<boolean>(true);
  const [ reserveInquiryExpanded, setReserveInquiryExpanded ] = useState<boolean>(false);
  const [ receptorInfoExpanded, setReceptorInfoExpanded ] = useState<boolean>(true);
  const [ baseInfoExpanded, setBaseInfoExpanded ] = useState<boolean>(true);
  
  const onClickLinkLine = useCallback(async () => {
    if (!isMobile) {
      openAlert("warning", "スマートフォンから開いてください。")
      return;
    }
    if (!isEnableLine) {
      openAlert("warning", "LINE連携に対応していません。")
      return;
    }
    setIsLinkingLine(true)
    const func = httpsCallable<{ id: string, clinicId: string }, { result: boolean, url: string | null, error: string | null }>(
      functions,
      'getLinkUrlForLine',
    )
    const res = await func({ id: state.reservationId ?? "", clinicId: reservationInfo?.clinicId ?? "" })
    if (!res.data.result) {
      openAlert("error", "LINE連携情報の取得に失敗しました。")
      setIsLinkingLine(false)
      return;
    }
    if (res.data.url) {
      window.open(res.data.url)
      setIsLinkingLine(false)
    }
  }, [ isEnableLine, openAlert, reservationInfo?.clinicId, state.reservationId ]);
  
  const onClickGoToSearchPage = useCallback(() => {
    navigate(`/patient/${reservationInfo?.clinicId ?? ""}`)
    resetState();
  }, [ navigate, reservationInfo?.clinicId, resetState ]);
  
  if (!reservationInfo) {
    return <div>予約情報が見つかりませんでした。</div>
  }
  
  const overTabletContents = <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={12} xl={10} my={2}>
      <Stack spacing={4}>
        {
          isEnableLine ? <Accordion expanded={notificationExpanded}
                                    onChange={() => setNotificationExpanded(!notificationExpanded)}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
              backgroundColor: "#4ec4d3",
              border: "3px double #000",
              opacity: 0.9
            }}>
              <Typography variant='h6' fontWeight="bold" color="white">
                LINE診察予約リマインド通知について
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant='body2' color="inherit">
                  入力頂いたメールアドレス宛に、診察予約完了のメッセージを送信しました。<br />
                  下記からLINE連携・友達登録をして頂くと、
                  LINEのメッセージで診察予約のリマインド通知を受け取れます。
                </Typography>
                <LoadingButton
                  variant='contained'
                  loading={isLinkingLine}
                  onClick={() => {
                    void onClickLinkLine();
                  }}
                  loadingIndicator="読込中..."
                >
                  <Stack>
                    <Typography variant='subtitle1' fontSize='20px' fontWeight='bold' textAlign='center' color='white'>
                      LINE通知の設定
                    </Typography>
                    <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                      はこちらから
                    </Typography>
                  </Stack>
                </LoadingButton>
              </Stack>
            </AccordionDetails>
          </Accordion> : null
        }
        
        <Accordion expanded={reserveInquiryExpanded}
                   onChange={() => setReserveInquiryExpanded(!reserveInquiryExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            backgroundColor: "#f4f4f4",
            border: "3px double #ccc"
          }}>
            <Typography variant='h6' fontWeight="bold">
              予約照会について
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack justifyContent="center" alignItems="center" spacing={2}>
              <Typography variant='body2' color="inherit">
                予約情報の照会や、診察の待ち状況などは下記ページから確認できます。
              </Typography>
              <Button variant='contained' onClick={onClickGoToSearchPage}>
                <Stack>
                  <Typography variant='subtitle1' fontSize='20px' fontWeight='bold' textAlign='center' color='white'>
                    予約照会ページ
                  </Typography>
                  <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                    はこちらから
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
        
        <Accordion expanded={receptorInfoExpanded} onChange={() => setReceptorInfoExpanded(!receptorInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受付・予約情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ width: "100%" }}>
                <TableBody>
                  {/* <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      予約ID
                    </TableCell>
                    <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                      {state.reservationId}
                      <CopyToClipboard text={state.reservationId} onCopy={() => {
                        openAlert('success', '予約IDをコピーしました。')
                      }}>
                        <IconButton size="small">
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      呼び出し番号
                    </TableCell>
                    <TableCell sx={{ width: "60%", color: "#362f5f" }} align="right">
                      {reservationInfo.number.toString().padStart(3, '0')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      診察日
                    </TableCell>
                    <TableCell sx={{ width: "60%", color: "#362f5f" }} align="right">
                      {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      診察時間枠
                    </TableCell>
                    <TableCell sx={{ width: "60%" }} align="right">
                      {`${time2string({
                        hour: reservationInfo.startHour,
                        minute: reservationInfo.startMinute,
                      })}
                        ~
                        ${time2string({
                        hour: reservationInfo.endHour,
                        minute: reservationInfo.endMinute,
                      })}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      診察券
                    </TableCell>
                    <TableCell sx={{ width: "60%" }} align="right">
                      {state.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                    </TableCell>
                  </TableRow>
                  {reservationInfo.hasPatientIdentification ? <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      診察券番号
                    </TableCell>
                    <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                      {reservationInfo.patientIdentification}
                    </TableCell>
                  </TableRow> : null}
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: "40%", fontSize: '16px' }}>
                      症状(自由入力)
                    </TableCell>
                    <TableCell sx={{ width: "60%" }} align="right"
                               style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                      {reservationInfo.reservationDetail}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        
        <Accordion expanded={baseInfoExpanded} onChange={() => setBaseInfoExpanded(!baseInfoExpanded)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
            borderLeft: "5px solid #000",
            background: "#f4f4f4"
          }}>
            <Typography variant='subtitle1' color="#000">
              受診者情報
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    お名前
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.examineeFirstName} {reservationInfo.examineeLastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    よみがな
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.examineeFirstKana} {reservationInfo.examineeLastKana}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {reservationInfo.examineeEmail}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    電話番号
                  </TableCell>
                  <TableCell sx={{ width: "60%", fontSize: '16px', fontWeight: 'bold' }} align="right">
                    {reservationInfo.examineePhone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    性別
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {genderIndex2string(reservationInfo.examineeGender)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                    生年月日
                  </TableCell>
                  <TableCell sx={{ width: "60%" }} align="right">
                    {format(new Date(reservationInfo.examineeBirthday), `yyyy年M月d日`, { locale: ja })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Grid>
  </Grid>;
  
  const mobileContents = <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Stack spacing={4}>
          <Accordion expanded={notificationExpanded}
                     onChange={() => setNotificationExpanded(!notificationExpanded)}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
              backgroundColor: "#4ec4d3",
              border: "3px double #000",
              opacity: 0.9
            }}>
              <Typography variant='subtitle1' fontWeight="bold" color="white">
                LINE診察予約リマインド通知について
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant='body2' color="inherit">
                  入力頂いたメールアドレス宛に、診察予約完了のメッセージを送信しました。<br />
                  下記からLINE連携・友達登録をして頂くと、
                  LINEのメッセージで診察予約のリマインド通知を受け取れます。
                </Typography>
                <LoadingButton
                  variant='contained'
                  loading={isLinkingLine}
                  onClick={() => {
                    void onClickLinkLine();
                  }}
                  loadingIndicator="読込中..."
                >
                  <Stack>
                    <Typography variant='subtitle1' fontSize='20px' fontWeight='bold' textAlign='center' color='white'>
                      LINE通知の設定
                    </Typography>
                    <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                      はこちらから
                    </Typography>
                  </Stack>
                </LoadingButton>
              </Stack>
            </AccordionDetails>
          </Accordion>
          
          <Accordion
            expanded={reserveInquiryExpanded}
            onChange={() => setReserveInquiryExpanded(!reserveInquiryExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2d-content"
              id="panel2d-header"
              sx={{
                backgroundColor: "#f4f4f4",
                border: "3px double #ccc"
              }}>
              <Typography variant='subtitle1' fontWeight="bold">
                予約照会について
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant='body2' color="inherit">
                  予約情報の照会や、診察の待ち状況などは下記ページから確認できます。
                </Typography>
                <Button variant='contained' onClick={onClickGoToSearchPage}>
                  <Stack>
                    <Typography variant='subtitle1' fontSize='20px' fontWeight='bold' textAlign='center' color='white'>
                      予約照会ページ
                    </Typography>
                    <Typography variant='body2' fontSize='12px' textAlign='center' color='white'>
                      はこちらから
                    </Typography>
                  </Stack>
                </Button>
              </Stack>
            </AccordionDetails>
          </Accordion>
          
          <Accordion
            expanded={receptorInfoExpanded}
            onChange={() => setReceptorInfoExpanded(!receptorInfoExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2d-content"
              id="panel2d-header"
              sx={{
                borderLeft: "5px solid #000",
                background: "#f4f4f4"
              }}>
              <Typography variant='subtitle1' color="#000">
                受付・予約情報
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                {/* <Stack direction='column' spacing={0} alignItems='center'>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    予約ID
                  </Typography>
                  <div>
                    {state.reservationId}
                    <CopyToClipboard text={state.reservationId} onCopy={() => {
                      openAlert('success', '予約IDをコピーしました。')
                    }}>
                      <IconButton size="small">
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </Stack> */}
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    呼び出し番号
                  </Typography>
                  <div>
                    {reservationInfo.number.toString().padStart(3, '0')}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    診察日
                  </Typography>
                  <div>
                    {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}`}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    診察時間枠
                  </Typography>
                  <div>
                    {`${time2string({
                      hour: reservationInfo.startHour,
                      minute: reservationInfo.startMinute,
                    })}
                        ~
                        ${time2string({
                      hour: reservationInfo.endHour,
                      minute: reservationInfo.endMinute,
                    })}`}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    診察券
                  </Typography>
                  <div>
                    {state.hasPatientIdentification ? '診察券をお持ちの方' : '診察券をお持ちでない方'}
                  </div>
                </Stack>
                
                {reservationInfo.hasPatientIdentification ?
                  <Stack direction='column' spacing={0}>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      診察券番号
                    </Typography>
                    <div>
                      {reservationInfo.patientIdentification}
                    </div>
                  </Stack> : null}
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    症状(自由入力)
                  </Typography>
                  <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                    {
                      reservationInfo.reservationDetail &&
                      reservationInfo.reservationDetail !== "" ?
                        reservationInfo.reservationDetail
                        : "入力無し"
                    }
                  </div>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          
          <Accordion
            expanded={baseInfoExpanded}
            onChange={() => setBaseInfoExpanded(!baseInfoExpanded)}
          >
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2d-content" id="panel2d-header" sx={{
              borderLeft: "5px solid #000",
              background: "#f4f4f4"
            }}>
              <Typography variant='subtitle1' color="#000">
                受診者情報
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                <Stack direction='column' spacing={0} alignItems='center'>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    お名前
                  </Typography>
                  <div>
                    {reservationInfo.examineeFirstName} {reservationInfo.examineeLastName}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    よみがな
                  </Typography>
                  <div>
                    {reservationInfo.examineeFirstKana} {reservationInfo.examineeLastKana}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    メールアドレス
                  </Typography>
                  <div>
                    {reservationInfo.examineeEmail}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    電話番号
                  </Typography>
                  <div>
                    {reservationInfo.examineePhone}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    性別
                  </Typography>
                  <div>
                    {genderIndex2string(reservationInfo.examineeGender)}
                  </div>
                </Stack>
                
                <Stack direction='column' spacing={0}>
                  <Typography variant='subtitle1' fontWeight='bold'>
                    生年月日
                  </Typography>
                  <div>
                    {format(new Date(reservationInfo.examineeBirthday), `yyyy年M月d日`, { locale: ja })}
                  </div>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Grid>
    </Grid>
  ;
  
  return (
    <>
      <Box sx={{
        display: {
          xs: 'none',
          sm: 'block',
        }
      }}>
        {overTabletContents}
      </Box>
      
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        }
      }}>
        {mobileContents}
      </Box>
    </>
  );
}

export default CompletedStep;