import {
  Box, Card, CardContent, Stack, Tab, Tabs, Typography
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import PatientPageBase from "../PageBase/PatientPageBase";
import ClinicInfoContent from "../ReservationPage/ClinicInfoContent";
import WaitingStatusContent from "./WaitingStatusContent";
import PatientIDTabContent from "./PatientIDTabContent";
import { ReservationInquiryPageTabsType } from "../../recoil/features/ReservationInquiryPage/atom";
import { getClinicInformation } from "../../repositories/clinicRepository";
import PhoneTabContent from "./PhoneTabContentProps";
import NotFoundClinicPage from "../NotFoundClinicPage/NotFoundClinicPage";

/**
 *
 * @constructor
 * @group Components
 * @category features/patient
 */
const ReservationSearchPage = () => {
  const params = useParams();
  const clinicId = useMemo(() => params.id, [ params.id ])
  
  const [ currentTab, setCurrentTab ] = useState<ReservationInquiryPageTabsType>("phone")
  
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: ReservationInquiryPageTabsType) => {
      setCurrentTab(newValue)
    }, []
  );
  
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', clinicId ?? "" ],
    () => getClinicInformation(clinicId ?? ""),
  );
  
  if (!(clinicId && clinicInfo)) {
    return NotFoundClinicPage();
  }
  
  return <PatientPageBase>
    <Stack direction='column' spacing={4}>
      <ClinicInfoContent clinicId={clinicId} />
      <WaitingStatusContent clinicId={clinicId} />
      <Stack spacing={0}>
        <Box mx={2} my={2}>
          <Stack direction='column' justifyContent='center' spacing={0} style={{ textAlign: 'center' }}>
            <Typography variant='h5' fontWeight='bold' mb={1}>
              予約照会フォーム
            </Typography>
            <Typography variant='body1'>
              「電話番号」「診察券番号」を利用して、予約情報の照会を行います。
            </Typography>
            <Typography variant='caption' fontWeight='bold' color='#c62828'>
              ※ 予約情報の変更やキャンセルがある場合はクリニックへの直接ご連絡をお願いします。
            </Typography>
          </Stack>
        </Box>
        <Card>
          <CardContent>
            <TabContext value={currentTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  variant='fullWidth'
                  aria-label="reservation settings tab"
                >
                  {/* <Tab label="予約ID" style={{ fontWeight: 'bold', fontSize: '16px' }} value="reserveId" /> */}
                  <Tab label="電話番号" style={{ fontWeight: 'bold', fontSize: '20px' }} value="phone" />
                  <Tab label="診察券番号" style={{ fontWeight: 'bold', fontSize: '20px' }} value="patientIdentification" />
                </Tabs>
              </Box>
              {/* <TabPanel value='reserveId'> */}
              {/* <ReserveIdTabContent /> */}
              {/* </TabPanel> */}
              <TabPanel value='phone'>
                <PhoneTabContent clinicInfo={clinicInfo} />
              </TabPanel>
              <TabPanel value='patientIdentification'>
                <PatientIDTabContent clinicInfo={clinicInfo} />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  </PatientPageBase>
}

export default ReservationSearchPage;